import React from 'react';
import Footer from '../components/footer';
import { Helmet } from 'react-helmet';
import Banner from '../components/banner';
import ServiceComponent from '../components/servicecomponent';
import './seoservice.css';
import Process from '../components/process';
import LetsCode from '../components/letscode';

const deg=`/images/clients/66deg-client.png`;
const cabi=`/images/clients/cabi.png`;
const proIt=`/images/clients/pro-it.png`;
const Scadea=`/images/clients/scadea-client.png`;
const nextLoop=`/images/clients/next-loop.png`

const elegantFemale = `/images/elegant-female.png`;
const customSoftwareDevelopment = `/images/icons/custom-software-development.png`;


const bannerDetails =
  {
      title: 'Our Services',
      subTitle: 'Social Media Service',
      description: 'we offer comprehensive social media management services designed to help businesses grow their online presence, engage with their audience, and drive results. '
  }

  const Services = [
    {
      image : customSoftwareDevelopment,
      heading : "You’re Falling Behind The Competition"
    },
    {
      image : customSoftwareDevelopment,
      heading : "You Can’t Hire An In-House SEO Team"
    },
    {
      image : customSoftwareDevelopment,
      heading : "You’ve Had Poor Results With Other Agencies"
    },
    {
      image : customSoftwareDevelopment,
      heading : "Your Traffic Is Declining / Not Growing"
    },
    {
      image : customSoftwareDevelopment,
      heading : "You Need More Leads / Sales"
    },
    {
      image : customSoftwareDevelopment,
      heading : "You’ve Experienced Poor Agency Communication"
    },
    {
      image : customSoftwareDevelopment,
      heading : "You Don’t Have Endless Resources"
    },
    {
      image : customSoftwareDevelopment,
      heading : "Other Agencies Didn’t Understand Your Business"
    },
    {
      image : customSoftwareDevelopment,
      heading : "You’re Not Sure What You Paid For In The Past"
    }
    ]

    const processCard = [
      {
          heading: 'What Our Social Media Services Include',
          subheading: "Our social media services are designed to meet all your business needs, from building a strong online presence to driving engagement and increasing conversions.",
          processItems: [
              {
                  num: '01',
                  image: customSoftwareDevelopment,
                  contentheading: 'Campaign Management',
              },
              {
                  num: '02',
                  image: customSoftwareDevelopment,
                  contentheading: 'Elite Targeting',
              },
              {
                num: '03',
                image: customSoftwareDevelopment,
                contentheading: 'Reporting & Analytics',
              }
          ]
      },
      {
  
      }
  ]

function SocialMedia() {
  return (
    <>
 <Helmet>
    <title>Social Media Services | Digital Marketing | Stamens Software - Enhance Your Brand’s Online Presence</title>
    <meta name="description" content="Stamens Software provides comprehensive social media services to help businesses build a strong online presence. Our expert strategies increase engagement, drive traffic, and boost brand awareness on social platforms." />
    <meta name="keywords" content="Social Media Services, Digital Marketing, Social Media Marketing, Brand Awareness, Social Media Strategy, Increase Engagement, Online Presence, Stamens Software" />
</Helmet>
    <Banner details={bannerDetails} descriptionClass={'text-center fs-5'}/>

    <section className="Challenge bg-white">
            <div className="container">
                <h2 className="head text-center">
                Tired of Dealing With Social Media Challenges?
                </h2>
                <p className="text-center fs-5 pb-4">From creating consistent, engaging content to keeping up with the latest trends and algorithm changes, it's a lot to juggle. But you don't have to do it alone.</p>
                <div className="row justify-content-center gy-4">
                {Services.map((card, index) => (
                  <ServiceComponent key={index} serviceprops={card} h4={'h3'} />
                ))}
                </div>
            </div>
        </section>

        {processCard.map((item, index) => (
        <Process 
          key={index}
          heading={item.heading}
          subheading={item.subheading}
          processItems={item.processItems}
          parentClass={'seo-include'}
          childClass={'row process'}
          childClassA={'col-lg-4 point'}
        />
      ))}

      <LetsCode />

      <section className="technologie-seo">
            <div className="container">
                <h3 className="head">
                    <span>Our Clients</span>
                    Real Results for Real SEO Clients
                </h3>
                <div className="row gy-4 justify-content-center">
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={deg} alt="client 1" className="img-fluid" width="237" height="103" />
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={cabi} alt="client 2" className="img-fluid" width="237" height="103" />
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={proIt} alt="client 3" className="img-fluid" width="237" height="103" />
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={Scadea} alt="client 4" className="img-fluid" width="237" height="103" />
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src="https://www.stamenssoftware.com/images/clients/techolution-client.png" alt="client 5" className="img-fluid" width="237" height="103" />
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={nextLoop} alt="client 6" className="img-fluid" width="237" height="103" />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="partner bg-white">
            <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-5 pt-4">
                            <img src={elegantFemale} alt="vision" className="img-fluid rounded" style={{height: '100%', objectFit: 'cover'}} />
                        </div>
                        <div className="col-lg-7">
                            <h4 className="head">
                                Partner with our client-centered team for top-quality software solutions
                            </h4>
                            <p className="mt-n2 mb-25">Looking for top-notch software developers? Look no further! Our team of certified experts is dedicated to providing a client-centered approach and delivering the highest quality software using the software development life cycle (SDLC). Our team is comprised of experienced programmers who are known for their outstanding consulting services and for writing clean code.</p>
                            <p className="mt-n2 mb-25">Trust us, one of the most reliable software outsourcing companies, to make your mission-critical project a success by hiring one of our talented software developers.</p>
                            <a href="#" className="hire-btn">Hire Us Now</a>
                        </div>
                    </div>
                </div>
        </section>

        <section className="our-company">
            <div className="container">
                <h4 className="head">
                    <span>Why Stamens Software</span>
                    We are one of the best IT companies?
                </h4>
                <div className="row gy-4">
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={customSoftwareDevelopment} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>15+ Years in Industry</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={customSoftwareDevelopment} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Source Code Delivery to Clients</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={customSoftwareDevelopment} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Microsoft 365, Azure</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={customSoftwareDevelopment} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Less than 24 hrs Guaranteed Response</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={customSoftwareDevelopment} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Certified Developers</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={customSoftwareDevelopment} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Dedicated Project Manager</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={customSoftwareDevelopment} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Clients: USA, UK, Australia, etc.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={customSoftwareDevelopment} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Handle Tight Deadlines</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>     
   
    <Footer />
    </>
  )
}

export default SocialMedia;
