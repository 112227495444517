import React from 'react'
import Footer from '../components/footer';
import './mvp.css';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Autoplay, Navigation } from 'swiper/modules';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ServiceComponent from '../components/servicecomponent';
import Banner from '../components/banner';

const customSoftwareDevelopment = `/images/icons/custom-software-development.png`;
const angular = `/images/tools/angular.svg`;
const bootstrap = `/images/tools/bootstrap.svg`;
const css = `/images/tools/css.svg`;
const html = `/images/tools/html.svg`;
const javaScript = `/images/tools/javaScript.svg`;
const materialUi = `/images/tools/material-ui.svg`;
const reactJs = `/images/tools/reactJs.svg`;
const typeScript = `/images/tools/typeScript.svg`;
const vueJs = `/images/tools/VueJs.svg`;
const dotNet = `/images/tools/dotnet.svg`;
const java = `/images/tools/java.svg`;
const python = `/images/tools/python.svg`;
const nodejs = `/images/tools/nodejs.svg`;
const php = `/images/tools/php.svg`;
const ios = `/images/tools/ios.svg`;
const android = `/images/tools/android.svg`;
const xamarin = `/images/tools/xamarin.svg`;
const cordova = `/images/tools/cordova.svg`;
const pwa = `/images/tools/pwa.svg`;
const reactNative = `/images/tools/react-native.svg`;
const flutter = `/images/tools/flutter.svg`;
const cplusplus = `/images/tools/cplusplus.svg`;
const quasar = `/images/tools/quasar-toolkit.svg`;
const csharp = `/images/tools/csharp.svg`;
const wpf = `/images/tools/wpf.svg`;
const obj = `/images/tools/obj.svg`;
const sqlServer = `/images/tools/sql-server.svg`;
const msql = `/images/tools/msql.svg`;
const azuresqlDb = `/images/tools/azure-sql-db.svg`;
const postgresql = `/images/tools/postgresql.svg`;
const cassandra = `/images/tools/cassandra.svg`;
const hive = `/images/tools/hive.svg`;
const hBase = `/images/tools/h-base.svg`;
const nifi = `/images/tools/nifi.svg`;
const amazonSimpleStorage = `/images/tools/amazon-simple-storage.svg`;
const amazonRedshift = `/images/tools/amazon-redshift.svg`;
const amazonRb = `/images/tools/amazon-accound-rb.svg`;
const amazonRds = `/images/tools/amazon_rds.svg`;
const elastic = `/images/tools/elastic.svg`;
const awsParnter = `/images/tools/aws-parnter.svg`;
const azuredataLake = `/images/tools/azure-data-lake.svg`;
const microsoft = `/images/tools/mircrosoft.svg`;
const oracle = `/images/tools/oracle.svg`;
const salesforce = `/images/tools/salesforce.svg`;
const serviceNow = `/images/tools/service-now.svg`;
const appMobile = `/images/app-mobile.png`;
const bankingFinance = `/images/industries/banking-and-finance.png`;
const educationElearning = `/images/industries/education-and-elearning.png`;
const fintech = `/images/industries/fintech.png`;
const healthcare = `/images/industries/healthcare.png`;
const logisticsTransport = `/images/industries/logistics-and-transport.png`;
const manufacturing = `/images/industries/manufacturing.png`;
const mediaEntertainment = `/images/industries/media-and-entertainment.png`;
const retailEcommerce = `/images/industries/retail-and-ecommerce.png`;
const travelTourism = `/images/industries/travel-and-tourism.png`;

const industry15 = `/images/icons/15-years-in-industry.png`;
const sourceCode = `/images/icons/source-code-delivery-to-clients.png`;
const microsoftAzure = `/images/icons/microsoft-365-azure.png`;
const response24 = `/images/icons/less-than-24-hrs-guaranteed-response.png`;
const certifiedDevelopers = `/images/icons/certified-developers.png`;
const projectManager = `/images/icons/dedicated-project-manager.png`;
const client = `/images/icons/clients-usa-uk-australia.png`;
const tightDeadlines = `/images/icons/handle-tight-deadlines.png`;


const industries = [
  { img: healthcare, alt: "Healthcare", name: "Healthcare" },
  { img: travelTourism, alt: "Travel & Tourism", name: "Travel & Tourism" },
  { img: bankingFinance, alt: "Banking & Finance", name: "Banking & Finance" },
  { img: logisticsTransport, alt: "Logistics & Transport", name: "Logistics & Transport" },
  { img: retailEcommerce, alt: "Retail & Ecommerce", name: "Retail & Ecommerce" },
  { img: mediaEntertainment, alt: "Media & Entertainment", name: "Media & Entertainment" },
  { img: educationElearning, alt: "Education & E-Learning", name: "Education & E-Learning" },
  { img: fintech, alt: "FinTech", name: "FinTech" },
  { img: manufacturing, alt: "Manufacturing", name: "Manufacturing" },
];

const serviceCard = [
    {
        heading: 'MVP Consulting',
        para: 'Maximize the potential of your idea with our expert MVP consulting services. We help you clarify your vision, define essential features, and develop a strategic roadmap for Minimum Viable Product success. This ensures your MVP is well-positioned to meet market needs.',
    },
    {
        heading: 'Prototype Designing',
        para: 'Visualize and refine your concept with our prototype designing services. By creating interactive prototypes, we help you test and validate key features, gather user feedback, and make informed decisions before investing in full-scale development.',
    },
    {
        heading: 'Pilot MVP Development',
        para: 'Test your MVP in real-world scenarios with our pilot development service. This allows you to evaluate core functionalities, measure user engagement, and make necessary adjustments, ensuring your MVP is well-prepared for broader market launch.',
    },
    {
        heading: 'End-to-End Development',
        para: 'Streamline your development process with our end-to-end services. We manage every stage from initial planning to final launch, ensuring a cohesive and comprehensive approach that integrates design, development, and testing for a successful MVP.',
    },
    {
        heading: 'Dedicated Team',
        para: 'Benefit from a dedicated team focused solely on your MVP project. Our specialized experts work closely with you to ensure efficient development, addressing your unique requirements and delivering high-quality results tailored to your needs.',
    },
    {
        heading: 'Quality Assurance',
        para: 'Achieve a reliable and robust MVP with our rigorous quality assurance. We conduct thorough testing to identify and resolve issues early, ensuring your product performs well and meets the highest standards before it reaches the market.',
    },
]

const bannerDetails =
{
    title: 'Our Services',
    subTitle: 'MVP Development',
    description: 'Our company provides a complete range of MVP development services for clients ranging from startups to enterprises across the globe.'
}

function Mvp() {
  return (
   <>

<Helmet>
<meta name="description" content="Stamens Software, a leading IT company in Noida, offers MVP development services to validate ideas, reduce risks, and deliver scalable, market-ready solutions."/>
<meta name="keywords" content="MVP Development Services in Noida, Custom MVP Development Services in Noida, MVP Design and Development in Noida"/>
    <title>MVP Development Company | MVP Development Services - Stamens Software</title>
    </Helmet>

    <Banner details={bannerDetails} descriptionClass={'text-center fs-5'}/>

        <section className="mvp-service bg-white">
            <div className="container">
                <h2 className="head text-center">
                    <span>We Offer</span>
                    MVP Development Services 
                </h2>
                <p className="text-center fs-5 pb-4">With a focus on agile methodologies and user-centric design, our MVP Development Services provide strategic planning and execution to bring your vision to life. We turn ideas into viable products, ensuring your project’s success from inception to launch.</p>
                <div className="center-sec">
                    <div className="row justify-content-center gy-4">
                    {serviceCard.map((card, index) => (
               <ServiceComponent  key={index} serviceprops={card} />
            ))}
                    </div>
                </div>
            </div>
        </section>

        <section className="technologies-mvp">
            <div className="container">
                <h3 className="head text-center">
                    Tech Stack
                </h3>
                <p className="text-center fs-5 pb-4">Discover the powerful technologies we leverage to modernize your legacy systems and drive business innovation.</p>

                <ul className="nav nav-tabs justify-content-center" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button className="nav-link active" id="techtab1-tab" data-bs-toggle="tab" data-bs-target="#techtab1-tab-pane" type="button" role="tab" aria-controls="techtab1-tab-pane" aria-selected="true">Frontend</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="techtab2-tab" data-bs-toggle="tab" data-bs-target="#techtab2-tab-pane" type="button" role="tab" aria-controls="techtab2-tab-pane" aria-selected="false">Backend</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="techtab3-tab" data-bs-toggle="tab" data-bs-target="#techtab3-tab-pane" type="button" role="tab" aria-controls="techtab3-tab-pane" aria-selected="false">Mobile</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="techtab4-tab" data-bs-toggle="tab" data-bs-target="#techtab4-tab-pane" type="button" role="tab" aria-controls="techtab4-tab-pane" aria-selected="false">Desktop</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="techtab5-tab" data-bs-toggle="tab" data-bs-target="#techtab5-tab-pane" type="button" role="tab" aria-controls="techtab5-tab-pane" aria-selected="false">Databases/data  Storages</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="techtab6-tab" data-bs-toggle="tab" data-bs-target="#techtab6-tab-pane" type="button" role="tab" aria-controls="techtab6-tab-pane" aria-selected="false">Cloud  Storages</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="techtab7-tab" data-bs-toggle="tab" data-bs-target="#techtab7-tab-pane" type="button" role="tab" aria-controls="techtab7-tab-pane" aria-selected="false">Strategic  Partnership</button>
                    </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="techtab1-tab-pane" role="tabpanel" aria-labelledby="techtab1-tab" tabIndex="0">
                        <div className="tools">
                            <div className="tool">
                                <div className="image">
                                    <img src={html} alt="html" className="img-fluid" />
                                </div>
                                <p>HTML</p>
                            </div>
                            <div className="tool">
                                <div className="image">
                                    <img src={css} alt="css" className="img-fluid" />
                                </div>
                                <p>CSS</p>
                            </div>
                            <div className="tool">
                                <div className="image">
                                    <img src={javaScript} alt="javascript" className="img-fluid" />
                                </div>
                                <p>javascript</p>
                            </div>
                            <div className="tool">
                                <div className="image">
                                    <img src={typeScript} alt="Typescript" className="img-fluid" />
                                </div>
                                <p>Typescript</p>
                            </div>
                            <div className="tool">
                                <div className="image">
                                    <img src={reactJs} alt="React js" className="img-fluid" />
                                </div>
                                <p>React.js</p>
                            </div>
                            <div className="tool">
                                <div className="image">
                                    <img src={angular} alt="Angular" className="img-fluid" />
                                </div>
                                <p>Angular</p>
                            </div>
                            <div className="tool">
                                <div className="image">
                                    <img src={vueJs} alt="Vue js" className="img-fluid" />
                                </div>
                                <p>Vue.js</p>
                            </div>
                            <div className="tool">
                                <div className="image">
                                    <img src={materialUi} alt="Material-UI" className="img-fluid" />
                                </div>
                                <p>Material-UI</p>
                            </div>
                            <div className="tool">
                                <div className="image">
                                    <img src={bootstrap} alt="Bootstrap" className="img-fluid" />
                                </div>
                                <p>Bootstrap</p>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="techtab2-tab-pane" role="tabpanel" aria-labelledby="techtab2-tab" tabIndex="0">
                        <div className="tools">
                            <div className="tool">
                                <div className="image">
                                    <img src={dotNet} alt="dotnet" className="img-fluid" />
                                </div>
                                <p>.Net</p>
                            </div>
                            <div className="tool">
                                <div className="image">
                                    <img src={java} alt="java" className="img-fluid" />
                                </div>
                                <p>Java</p>
                            </div>
                            <div className="tool">
                                <div className="image">
                                    <img src={python} alt="python" className="img-fluid" />
                                </div>
                                <p>Python</p>
                            </div>
                            <div className="tool">
                                <div className="image">
                                    <img src={nodejs} alt="nodejs" className="img-fluid" />
                                </div>
                                <p>Node js</p>
                            </div>
                            <div className="tool">
                                <div className="image">
                                    <img src={php} alt="php" className="img-fluid" />
                                </div>
                                <p>PHP</p>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="techtab3-tab-pane" role="tabpanel" aria-labelledby="techtab3-tab" tabIndex="0">
                        <div className="tools">
                            <div className="tool">
                                <div className="image">
                                    <img src={ios} alt="ios" className="img-fluid" />
                                </div>
                                <p>IOS</p>
                            </div>
                            <div className="tool">
                                <div className="image">
                                    <img src={android} alt="android" className="img-fluid" />
                                </div>
                                <p>Android</p>
                            </div>
                            <div className="tool">
                                <div className="image">
                                    <img src={xamarin} alt="xamarin" className="img-fluid" />
                                </div>
                                <p>Xamarin</p>
                            </div>
                            <div className="tool">
                                <div className="image">
                                    <img src={cordova} alt="cordova" className="img-fluid" />
                                </div>
                                <p>Cordova</p>
                            </div>
                            <div className="tool">
                                <div className="image">
                                    <img src={pwa} alt="pwa" className="img-fluid" />
                                </div>
                                <p>PWA</p>
                            </div>
                            <div className="tool">
                                <div className="image">
                                    <img src={reactNative} alt="react-native" className="img-fluid" />
                                </div>
                                <p>React Native</p>
                            </div>
                            <div className="tool">
                                <div className="image">
                                    <img src={flutter} alt="flutter" className="img-fluid" />
                                </div>
                                <p>flutter</p>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="techtab4-tab-pane" role="tabpanel" aria-labelledby="techtab4-tab" tabIndex="0">
                        <div className="tools">
                            <div className="tool">
                                <div className="image">
                                    <img src={cplusplus} alt="C++" className="img-fluid" />
                                </div>
                                <p>C++</p>
                            </div>
                            <div className="tool">
                                <div className="image">
                                    <img src={quasar} alt="quasar-toolkit" className="img-fluid" />
                                </div>
                                <p>Quasar Toolkit</p>
                            </div>
                            <div className="tool">
                                <div className="image">
                                    <img src={csharp} alt="csharp" className="img-fluid" />
                                </div>
                                <p>C#</p>
                            </div>
                            <div className="tool">
                                <div className="image">
                                    <img src={wpf} alt="wpf" className="img-fluid" />
                                </div>
                                <p>WPF</p>
                            </div>
                            <div className="tool">
                                <div className="image">
                                    <img src={obj} alt="obj" className="img-fluid" />
                                </div>
                                <p>OBJ-C</p>
                            </div>
                            <div className="tool">
                                <div className="image">
                                    <img src={python} alt="python" className="img-fluid" />
                                </div>
                                <p>Python</p>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="techtab5-tab-pane" role="tabpanel" aria-labelledby="techtab5-tab" tabIndex="0">
                        <div className="tools">
                            <div className="tool">
                                <div className="image">
                                    <img src={sqlServer} alt="sql-server" className="img-fluid" />
                                </div>
                                <p>SQL Server</p>
                            </div>
                            <div className="tool">
                                <div className="image">
                                    <img src={msql} alt="msql" className="img-fluid" />
                                </div>
                                <p>MySQL</p>
                            </div>
                            <div className="tool">
                                <div className="image">
                                    <img src={azuresqlDb} alt="Azure SQL Database" className="img-fluid" />
                                </div>
                                <p>Azure SQL Database</p>
                            </div>
                            <div className="tool">
                                <div className="image">
                                    <img src={postgresql} alt="postgresql" className="img-fluid" />
                                </div>
                                <p>postgresql</p>
                            </div>
                            <div className="tool">
                                <div className="image">
                                    <img src={cassandra} alt="cassandra" className="img-fluid" />
                                </div>
                                <p>cassandra</p>
                            </div>
                            <div className="tool">
                                <div className="image">
                                    <img src={angular} alt="Angular" className="img-fluid" />
                                </div>
                                <p>Angular</p>
                            </div>
                            <div className="tool">
                                <div className="image">
                                    <img src={hive} alt="hive" className="img-fluid" />
                                </div>
                                <p>HIVE</p>
                            </div>
                            <div className="tool">
                                <div className="image">
                                    <img src={hBase} alt="h-base" className="img-fluid" />
                                </div>
                                <p>HBASE</p>
                            </div>
                            <div className="tool">
                                <div className="image">
                                    <img src={nifi} alt="nifi" className="img-fluid" />
                                </div>
                                <p>nifi</p>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="techtab6-tab-pane" role="tabpanel" aria-labelledby="techtab6-tab" tabIndex="0">
                        <div className="tools">
                            <div className="tool">
                                <div className="image">
                                    <img src={amazonSimpleStorage} alt="amazon-simple-storage" className="img-fluid" />
                                </div>
                                <p>Amazon Simple Storage</p>
                            </div>
                            <div className="tool">
                                <div className="image">
                                    <img src={amazonRedshift} alt="amazon-redshift" className="img-fluid" />
                                </div>
                                <p>Amazon Redshift</p>
                            </div>
                            <div className="tool">
                                <div className="image">
                                    <img src={amazonRb} alt="amazon-accound-rb" className="img-fluid" />
                                </div>
                                <p>Amazon DocumentDB</p>
                            </div>
                            <div className="tool">
                                <div className="image">
                                    <img src={amazonRds} alt="amazon_rds" className="img-fluid" />
                                </div>
                                <p>Amazon RDS</p>
                            </div>
                            <div className="tool">
                                <div className="image">
                                    <img src={elastic} alt="elastic" className="img-fluid" />
                                </div>
                                <p>AWS Elasticache</p>
                            </div>
                            <div className="tool">
                                <div className="image">
                                    <img src={azuredataLake} alt="azure-data-lake" className="img-fluid" />
                                </div>
                                <p>Azure Data Lake</p>
                            </div>
                            <div className="tool">
                                <div className="image">
                                    <img src={azuresqlDb} alt="azure-sql-db" className="img-fluid" />
                                </div>
                                <p>Azure SQL DB</p>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="techtab7-tab-pane" role="tabpanel" aria-labelledby="techtab7-tab" tabIndex="0">
                        <div className="tools">
                            <div className="tool">
                                <div className="image">
                                    <img src={microsoft} alt="mircrosoft" className="img-fluid" />
                                </div>
                                <p>Mircrosoft</p>
                            </div>
                            <div className="tool">
                                <div className="image">
                                    <img src={oracle} alt="oracle" className="img-fluid" />
                                </div>
                                <p>Oracle</p>
                            </div>
                            <div className="tool">
                                <div className="image">
                                    <img src={awsParnter} alt="aws-parnter" className="img-fluid" />
                                </div>
                                <p>AWS Parnter</p>
                            </div>
                            <div className="tool">
                                <div className="image">
                                    <img src={salesforce} alt="salesforce" className="img-fluid" />
                                </div>
                                <p>Sales Force</p>
                            </div>
                            <div className="tool">
                                <div className="image">
                                    <img src={serviceNow} alt="service-now" className="img-fluid" />
                                </div>
                                <p>Service Now</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="connect">
            <div className="container">
                <h3 className="head"><span>Let's Code</span> Your Success Story Together</h3>
                <p className="tagline col-md-9 mx-auto text-center fs-4 mb-5 d-block">Get in touch with our skilled developers to begin receiving the bestdesign and developmentservices for your ultimate website</p>
                <div className="btn-group">
                    <Link to="/contact"><i className="fa fa-clipboard-question"></i> Ask a Query</Link>
                    <a href="tel:+911203500848"><i className="fa fa-phone" aria-hidden="true"></i> Call Now</a>
                </div>
            </div>
        </section>

        <section className="process-mvp">
            <div className="container">
                <h3 className="head text-center">
                    Our MVP Development Process
                </h3>
                <p className="text-center fs-5 pb-4">We have a tried-and-tested process in place for developing time-efficient MVPs tailored to your specific needs. You can trust us to deliver results that exceed your expectations. Let’s have a look at our MVP development process.</p>
                
                <div className="row justify-content-center gy-4">
                    <div className="col-lg-4">
                        <div className="part">
                            <h4 className="head">Discovery and MVP Planning</h4>
                            <p>The first step is to finalize your project requirements for MVP development. It will help us and your team to plan the production.</p>
                            <ul>
                                <li>Business analysis for product</li>
                                <li>Feature road-mapping</li>
                                <li>MVP architecture design</li>
                                <li>Planning MVP integrations</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="part">
                            <h4 className="head">PoC and Rapid Prototyping</h4>
                            <p>The first step is to finalize your project requirements for MVP development. It will help us and your team to plan the production.</p>
                            <ul>
                                <li>Business analysis for product</li>
                                <li>Feature road-mapping</li>
                                <li>MVP architecture design</li>
                                <li>Planning MVP integrations</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="part">
                            <h4 className="head">Development Project Planning</h4>
                            <p>The first step is to finalize your project requirements for MVP development. It will help us and your team to plan the production.</p>
                            <ul>
                                <li>Business analysis for product</li>
                                <li>Feature road-mapping</li>
                                <li>MVP architecture design</li>
                                <li>Planning MVP integrations</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="part">
                            <h4 className="head">MVP Development</h4>
                            <p>The first step is to finalize your project requirements for MVP development. It will help us and your team to plan the production.</p>
                            <ul>
                                <li>Business analysis for product</li>
                                <li>Feature road-mapping</li>
                                <li>MVP architecture design</li>
                                <li>Planning MVP integrations</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="part">
                            <h4 className="head">MVP Testing and Launch</h4>
                            <p>The first step is to finalize your project requirements for MVP development. It will help us and your team to plan the production.</p>
                            <ul>
                                <li>Business analysis for product</li>
                                <li>Feature road-mapping</li>
                                <li>MVP architecture design</li>
                                <li>Planning MVP integrations</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="part">
                            <h4 className="head">Support</h4>
                            <p>The first step is to finalize your project requirements for MVP development. It will help us and your team to plan the production.</p>
                            <ul>
                                <li>Business analysis for product</li>
                                <li>Feature road-mapping</li>
                                <li>MVP architecture design</li>
                                <li>Planning MVP integrations</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="text-center">
                    <Link to="/contact" className="requirements">DISCUSS YOUR REQUIREMENTS</Link>
                </div>

            </div>
        </section>


        <section className="partner bg-white">
            <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-5 pt-4">
                            <img src={appMobile} alt="mvp development" className="img-fluid" />
                        </div>
                        <div className="col-lg-7">
                            <h4 className="head">
                                Partner with our client-centered team for top-quality software solutions
                            </h4>
                            <p className="mt-n2 mb-25">Looking for top-notch software developers? Look no further! Our team of certified experts is dedicated to providing a client-centered approach and delivering the highest quality software using the software development life cycle (SDLC). Our team is comprised of experienced programmers who are known for their outstanding consulting services and for writing clean code.</p>
                            <p className="mt-n2 mb-25">Trust us, one of the most reliable software outsourcing companies, to make your mission-critical project a success by hiring one of our talented software developers.</p>
                            <Link to="/contact" className="hire-btn">Hire Us Now</Link>
                        </div>
                    </div>
                </div>
        </section>


        <section className="industry-mvp">
            <div className="heading">Industries</div>
            <Swiper
                modules={[Navigation, Autoplay]}
                className="mySwiper2"
                navigation={{
                    prevEl: '.swiper-button-prev2',
                    nextEl: '.swiper-button-next2',
                }}
                slidesPerView={4}
                spaceBetween={0}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false, 
              }}
            >
                {industries.map((industry, index) => (
                    <SwiperSlide key={index}>
                        <div className="part">
                            <div className="image">
                                <img src={industry.img} alt={industry.name} className="img-fluid" />
                            </div>
                            <div className="content">
                                <p className="name">{industry.name}</p>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>

            <div className="swiper-button-prev2">
                <i className="fa-solid fa-arrow-left"></i>
            </div>
            <div className="swiper-button-next2">
                <i className="fa-solid fa-arrow-right"></i>
            </div>
        </section>

        <section className="our-company-mvp">
            <div className="container">
                <h4 className="head">
                    <span>Why Stamens Software</span>
                    We are one of the best IT companies?
                </h4>
                <div className="row gy-4">
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={industry15} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>15+ Years in Industry</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={sourceCode} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Source Code Delivery to Clients</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={microsoftAzure} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Microsoft 365, Azure</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={response24} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Less than 24 hrs Guaranteed Response</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={certifiedDevelopers} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Certified Developers</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={projectManager} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Dedicated Project Manager</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={client} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Clients: USA, UK, Australia, etc.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={tightDeadlines} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Handle Tight Deadlines</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
   
   <Footer />
   </>
  )
}

export default Mvp;
