import React from 'react';
import './applicationMaintenance.css';
import { canUseDOM, Helmet } from 'react-helmet';
import Banner from '../components/banner';
import Footer from '../components/footer';
import ServiceComponent from '../components/servicecomponent';
import LetsCode from '../components/letscode';
import { Link } from 'react-router-dom';

const MultiTieredSupport = `/images/application-maintenance/multi-tiered-support.png`;
const MobileAppMaintenance = `/images/application-maintenance/mobile-app-maintenance.png`;
const WebAppMaintenance = `/images/application-maintenance/web-app-maintenance.png`;
const codeRefactoring = `/images/application-maintenance/code-refactoring.png`;
const Testing = `/images/application-maintenance/testing.png`;

const Implementation = `/images/application-maintenance/implementation.png`;
const Customization = `/images/application-maintenance/customization.png`;
const Integration = `/images/application-maintenance/integration.png`;
const Security = `/images/application-maintenance/training.png`;
const Training = `/images/application-maintenance/security.png`;
const Performance = `/images/application-maintenance/performance.png`;
const Reporting = `/images/application-maintenance/reporting.png`;

const Detection = `/images/application-maintenance/detection.png`;

const industry15 = `/images/icons/15-years-in-industry.png`;
const sourceCode = `/images/icons/source-code-delivery-to-clients.png`;
const microsoftAzure = `/images/icons/microsoft-365-azure.png`;
const response24 = `/images/icons/less-than-24-hrs-guaranteed-response.png`;
const certifiedDevelopers = `/images/icons/certified-developers.png`;
const projectManager = `/images/icons/dedicated-project-manager.png`;
const clients = `/images/icons/clients-usa-uk-australia.png`;
const tightDeadlines = `/images/icons/handle-tight-deadlines.png`;

const appMobile = `/images/app-mobile.png`;

const bannerDetails =
{
    title: 'Our Services',
    subTitle: 'APPLICATION MAINTENANCE AND SUPPORT SERVICES'
}

const Innovation = [
  {
    image: MultiTieredSupport,
    heading: 'Multi-Tiered Support Solutions',
    para: 'Our Application Support Engineers (ASE) offer comprehensive, multi-tiered support, addressing routine issues, conducting system audits, managing IT environments, and handling server administration. We also provide adaptive and perfective maintenance, on-demand troubleshooting, legacy migrations, system upgrades, and more.',
  },
  {
    image: MobileAppMaintenance,
    heading: 'Mobile App Maintenance and Support',
    para: 'We specialize in mobile app maintenance to enhance uptime, accommodate growing user bases, optimize server performance, improve UX/UI, reduce uninstall rates, ensure regulatory compliance, and maintain smooth operation of integrated web services.',
  },
  {
    image: WebAppMaintenance,
    heading: 'Web Application Maintenance',
    para: 'Our web application maintenance services include thorough testing, debugging, database upkeep, real-time monitoring, and updating site content, all performed in strict alignment with Service Level Agreement (SLA) standards.',
  },
  {
    image: codeRefactoring,
    heading: 'Code Refactoring',
    para: 'We revamp existing code to improve functionality, reduce redundancies, refine class designs, and enhance API implementations. Additionally, we transition basic services like RESTful and SOAP into efficient microservices.',
  },
  {
    image: WebAppMaintenance,
    heading: 'Software Maintenance and Upgrades',
    para: 'Our proactive maintenance and upgrade services keep your software secure and high-performing through regular updates and enhancements, ensuring your applications stay ahead in the evolving tech landscape.',
  },
  {
    image: Testing,
    heading: 'Quality Assurance & Testing',
    para: 'Our quality assurance and testing methodologies ensure high-performing applications through detailed issue diagnosis, rigorous testing, and ongoing performance evaluations. Using advanced tools and techniques, we deliver seamless processes and reliable application performance.',
  },
]

const Support = [
  {
    image: Implementation,
    heading: 'Implementation',
    para: 'Integrate advanced solutions seamlessly into your system. Our expert digital architects carefully plan and execute deployments, ensuring smooth transitions that enhance the functionality and efficiency of your applications from the start.'
  },
  {
    image: WebAppMaintenance,
    heading: 'Extended App Support',
    para: 'Our experienced application support engineers offer 24/7 assistance to resolve technical issues promptly. From maintaining peak performance through audits and server management to providing extended support for continuous improvement, we ensure your applications remain secure, updated, and aligned with the latest technological advancements.'
  },
  {
    image: Customization,
    heading: 'Customization',
    para: 'Our web application maintenance services include thorough testing, debugging, database upkeep, real-time monitoring, and updating site content, all performed in strict alignment with Service Level Agreement (SLA) standards.'
  },
  {
    image: Integration,
    heading: 'Integration',
    para: 'Connect your systems effortlessly with our integration services. We ensure smooth data flow between applications, boosting efficiency and creating a cohesive technology environment that supports your business goals.'
  },
  {
    image: Training,
    heading: 'Personalized Training and Consulting',
    para: 'Empower your team with training designed specifically for your needs. Our experts deliver personalized guidance, ensuring your team can effectively use and maximize the potential of your applications, fostering continuous improvement and operational expertise.'
  },
  {
    image: MobileAppMaintenance,
    heading: 'Responsive 24/7 Helpdesk Support',
    para: 'Enjoy peace of mind with round-the-clock helpdesk support. Our responsive team is dedicated to quickly resolving issues, ensuring uninterrupted application performance, and maintaining reliability at all times.'
  },
  {
    image: Security,
    heading: 'Security Protocols',
    para: 'Protect your applications with advanced security measures. From data encryption to regular audits, our services focus on safeguarding sensitive information, maintaining compliance with industry standards, and giving you confidence in the integrity of your digital assets.'
  },
  {
    image: Performance,
    heading: 'Performance Optimization',
    para: 'Elevate your applications beyond basic maintenance with performance optimization. We identify and address bottlenecks, refine processes, and implement enhancements to improve user experience and operational efficiency, ensuring your applications perform at their best.'
  },
  {
    image: MultiTieredSupport,
    heading: 'Scalability Solutions',
    para: "Prepare your technology for the future with scalable solutions. Our services adapt seamlessly to growing demands, whether it's accommodating larger user bases or integrating new features, ensuring your applications remain efficient, agile, and ready to grow with your business."
  },
  {
    image: Reporting,
    heading: 'Comprehensive Reporting',
    para: "Gain a clear view of your application's performance with detailed reporting. Our analytics provide valuable insights into key metrics and trends, empowering you to make data-driven decisions for strategic planning and ongoing optimization."
  }
]

const AIDrivenSolutions = [
  {
    image: MultiTieredSupport,
    heading: 'Automated Issue Resolution',
    para: 'Our expert tech team utilizes advanced AI algorithms and automation tools to quickly identify, diagnose, and resolve issues within applications. By focusing on real-time detection, this system eliminates the need for manual intervention, reduces downtime, and ensures continuous functionality, delivering a more efficient and effective support experience.',
  },
  {
    image: MobileAppMaintenance,
    heading: 'Cognitive Support Assistance',
    para: 'Harness the power of cognitive computing technology for streamlined support. Our intelligent assistance optimizes processes by automating routine tasks and enhancing workflow efficiency. Achieve operational excellence and improve user experiences with advanced cognitive support.',
  },
  {
    image: Integration,
    heading: 'Machine Learning Integration',
    para: "Unlock the potential of Machine Learning seamlessly integrated into our support services. This advanced data analysis and adaptive learning approach enhances decision-making, providing continuous feedback and assessment, ensuring a proactive and intelligent strategy for maintaining your application's health.",
  },
  {
    image: Customization,
    heading: 'Natural Language Processing (NLP)',
    para: 'Transform your support services with Natural Language Processing (NLP), enabling smooth communication between users and applications. NLP powers intelligent chatbots, precise ticket analysis, sentiment tracking, and more, enhancing your support experience with improved linguistic understanding and response capabilities.',
  },
  {
    image: Detection,
    heading: 'Anomaly Detection and Remediation',
    para: 'Benefit from AI-powered anomaly detection to protect your applications from irregular patterns and potential threats. Our advanced algorithms offer real-time insights for prompt issue resolution, ensuring your applications remain secure and stable.',
  },
  {
    image: Reporting,
    heading: 'Predictive Analytics',
    para: 'Our tech experts use statistical algorithms and Machine Learning models to analyze both historical and real-time data, predicting potential issues, optimizing performance, and proactively tackling challenges to ensure the smooth operation of your applications.',
  },
]

function ApplicationMaintenance() {
  return (
   <>
  <Helmet>
   <meta name="description" content="Stamens Software, a leading IT company in Noida, provides application maintenance services to ensure optimal performance, security, and continuous improvements."/>
   <meta name="keywords" content="Application Support in Noida, Software Maintenance Services in Noida"/>
   <title>Application Maintenance Services by Stamens Software</title>
</Helmet>
    
   <Banner details={bannerDetails} descriptionClass={'text-center fs-5'}/>

   <section className="our-fetaures-appMaintenance">
            <div className="container">
                <h3 className="head"><span>Stamens</span>
                    Your Trusted Partner in Innovation
                </h3>
                <p className="tagline">With over 15+ years of experience, Stamems Software specializes in delivering exceptional software development and application support solutions. From integration and migration to maintenance, upgrades, and tailored training, our expert team ensures your applications are optimized for maximum functionality and efficiency from the very start.</p>
                <div className="row justify-content-center gy-4">
                {Innovation.map((card, index) => (
               <ServiceComponent  key={index} serviceprops={card} parentClass={'col-lg-4 col-md-6'} childClassA={'small-head'} />
            ))}  
                </div>
            </div>
        </section>

        <section className="work-process-appMaintenance">
            <div className="container">
                <h3 className="head text-center">Application Support Services</h3>
                <p className="tagline text-center">Applications are valuable long-term assets, and our Application Support Services provide comprehensive solutions, including technical expertise, software maintenance, extended support, and outstanding customer care, ensuring your applications operate at peak performance.</p>
                <div className="row gy-4">
                {Support.map((card, index) => (
               <ServiceComponent  key={index} serviceprops={card} parentClass={'col-md-6'} childClassA={'small-head'} />
            ))}       
                </div>
            </div>
        </section>

        <LetsCode />

        <section className="application-solutions-appMaintenance">
            <div className="container">
                <h3 className="head">Transform Your Applications with AI-Driven Solutions</h3>
                <p className="tagline">Redefine your applications with our advanced AI-powered solutions. Embrace the next generation of application support, where cutting-edge innovation enhances performance and elevates user satisfaction to new heights.</p>
                <div className="row gy-4">
                {AIDrivenSolutions.map((card, index) => (
               <ServiceComponent  key={index} serviceprops={card} parentClass={'col-md-6'} childClassA={'small-head'} />
            ))}     
                </div>
            </div>
        </section>

        <section className="our-company-appMaintenance">
            <div className="container">
                <h4 className="head">
                    <span>Why Stamens Software</span>
                    We are one of the best IT companies?
                </h4>
                <div className="row gy-4">
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={industry15} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>15+ Years in Industry</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={sourceCode} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Source Code Delivery to Clients</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={microsoftAzure} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Microsoft 365, Azure</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={response24} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Less than 24 hrs Guaranteed Response</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={certifiedDevelopers} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Certified Developers</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={projectManager} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Dedicated Project Manager</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={clients} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Clients: USA, UK, Australia, etc.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={tightDeadlines} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Handle Tight Deadlines</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>   

        <section className="app-mobile-appMaintenance">
            <div className="container">
                <div className="row d-flex align-items-center">
                    <div className="col-md-4">
                        <img src={appMobile} alt="app-mobile" className="img-fluid" />
                    </div>
                    <div className="col-md-8">
                        <h3 className="head"> Partner with our client-centered team for top-quality software solutions </h3>
                        <p>Our team of skilled developers have years of experience in developing mobile apps for a range of platforms, including iOS, Android, and Windows. We use the latest tools and technologies to ensure that our apps are optimized for performance, security, and usability.</p>
                        <p>We create seamless and intuitive iOS applications that deliver top-notch user experiences for iPhones and iPads. Our Android apps are designed to perform exceptionally across a wide range of devices with an optimized user experience.</p>
                        <p>Using frameworks like Flutter and React Native, we build apps that work efficiently across both iOS and Android platforms. Our design team creates visually stunning, user-centered interfaces that engage users and enhance app usability.</p>  
                        <Link to="/contact" className="hire-btn">Hire Us Now</Link>          
                    </div>
                </div>
            </div>
        </section>

   <Footer />

   </>
  )
}

export default ApplicationMaintenance;
