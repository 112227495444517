import React, {useEffect, useState} from "react";
import { Helmet } from "react-helmet";
import "./gallerynew.css";
import Banner from "../components/banner";
import Footer from "../components/footer";
import GLightbox from "glightbox";
import "aos/dist/aos.css";
import "glightbox/dist/css/glightbox.min.css";

const profilePhoto = `/images/gallery/profile-photo.png`;

const bannerDetails = {
  title: "Our gallery",
  subTitle: "Stamens Software Gallery",
};

function GalleryNew() {

  const photos = {
    tour: [
      "tour/1.png",
      "tour/2.png",
      "tour/3.png",
      "tour/4.png",
      "tour/5.png",
      "tour/6.png",
      "tour/7.png",
      "tour/8.png",
      "tour/9.png",
      "tour/10.png",
      "tour/11.png",
      "tour/12.png",
      "tour/13.png",
      "tour/14.png",
      "tour/15.png",
      "tour/16.png",
      "tour/17.png",
      "tour/18.png",
      "tour/19.png",
      "tour/20.png",
      "tour/21.png",
      "tour/22.png",
      "tour/23.png",
      "tour/24.png",
      "tour/25.png",
      "tour/26.png",
    ],
    christmas: [
      "celebration-1.png",
      "celebration-2.png",
      "celebration-3.png",
      "celebration-4.png",
      "celebration-5.png",
      "celebration-6.png",
      "celebration-7.png",
      "celebration-8.png",
      "celebration-9.png",
      "celebration-10.png"
    ],
    diwali: [
      "diwali/1.png",
      "diwali/2.png",
      "diwali/3.png",
      "diwali/4.png",
      "diwali/5.png",
    ],
  };

  useEffect(() => {
    const categories = ["tour", "christmas", "diwali", "allphotos"];

    const lightboxes = categories.map((category) =>
      GLightbox({
        selector: `.glightbox[data-group="${category}"]`,
        loop: true,
        touchNavigation: true,
        closeButton: true,
        prevText: "Prev",
        nextText: "Next",
        openEffect: "fade",
        gallery: {selector: `.glightbox[data-group="${category}"]` }
      })
    );

    return () => {
      lightboxes.forEach((lightbox) => lightbox.destroy());
    };
  }, []);

  const handleSeeAllPhotos = () => {
    const allPhotosLightbox = GLightbox({
      selector: `.glightbox[data-group="allphotos"]`,
      loop: true,
      touchNavigation: true,
      closeButton: true,
      prevText: "Prev",
      nextText: "Next",
      openEffect: "fade",
      gallery: { selector: `.glightbox[data-group="allphotos"]` },
    });
    allPhotosLightbox.open();
  };


  const renderPhotos = (category, groupAllPhotos = false) => {
    const photosToDisplay = photos[category];
    const renderPhotoItem = (photo, isVisible) => (
     
      <div
        className="col-md-6"
        key={photo}
        style={{ display: isVisible ? "block" : "none", width: '50%', height: '225px', padding: '2px'}}
      >
        <div className="gallery-item">
          <a
            href={`/images/gallery/${photo}`}
            className="glightbox"
            data-group={groupAllPhotos ? "allphotos" : category}
          >
            <img
              src={`/images/gallery/${photo}`}
              alt={category}
              className="img-fluid"
            />
            
          </a>
        </div>
      </div>
    );
    return photosToDisplay.map((photo, index) => {
      const isVisible = index < 4;
      return renderPhotoItem(photo, isVisible);
    });
  };

  const tourDescription = `
    New Year <span>#Company</span> Tour to <span>#Jaisalmer</span>, <span>#Rajasthan</span>: 2025
    <br/>
    A <span>#desert #adventure</span> by "STAMENS SOFTWARE" Our company, 
    <a href="https://www.stamenssoftware.com/">Stamens Software</a> recently embarked on an exciting tour to Jaisalmer, 
    Rajasthan, a city nestled in the heart of the Thar Desert. The 4-day trip was a perfect blend of team-building, 
    cultural immersion, and adventure.
  `;

  const christmasDescription = `
    Celebrate the festive season with "STAMENS SOFTWARE" as we come together to celebrate Christmas!
    Our team embraced the spirit of joy, togetherness, and giving with colorful decorations, lights, and a variety of fun activities. 
    Check out the moments captured during our Christmas celebration this year. 
    <br />
    The season's greetings from <a href="https://www.stamenssoftware.com/">Stamens Software</a> – wishing you a Merry Christmas and a Happy New Year!
  `;

  const diwaliDescription = `
    Diwali, the festival of lights, brought an aura of positivity and warmth to "STAMENS SOFTWARE" this year!
    Our team joined hands to celebrate the joyous occasion with lights, sweets, and prayers. 
    Check out the vibrant images from our Diwali celebrations, filled with enthusiasm and harmony.
    <br />
    <a href="https://www.stamenssoftware.com/">Stamens Software</a> wishes you a prosperous and joyful Diwali!
  `;

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Stamens Software Gallery"
        />
        <meta
          name="keywords"
          content="Stamens Software, Gallery, Photos"
        />
        <title>Stamens Software Gallery</title>
      </Helmet>

      <Banner details={bannerDetails} />

      <section className="gallery">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-5">
              <div className="info-part">
                <h2 className="head">Intro</h2>
                <p>
                  Stamens Software is one of the leading companies, providing
                  best software services and solutions to their clients.
                </p>
                <ul>
                  <li>
                    <a href="tel:+9101203500847">
                      <i
                        className="fa fa-phone"
                        aria-label="phone"
                      ></i>
                      0120 350 0847
                    </a>
                  </li>
                  <li>
                    <a href="tel:+9101203500848">
                      <i
                        className="fa fa-phone"
                        aria-label="phone"
                      ></i>
                      0120 350 0848
                    </a>
                  </li>
                  <li>
                    <a href="mailto:contact@stamenssoftware.com">
                      <i
                        className="fa fa-envelope"
                        aria-label="mail"
                      ></i>
                      contact@stamenssoftware.com
                    </a>
                  </li>
                  <li>
                    <i
                      className="fa fa-location-dot"
                      aria-label="map"
                    ></i>
                    B-33, 2nd Floor, Sector-6, Noida, Uttar Pradesh, 201301,
                    India
                  </li>
                </ul>
              </div>

              <div className="all-photos-sec">
                <div className="head-part">
                  <h3 className="head">Photos</h3>
                  <button id="seeAllPhoto" onClick={handleSeeAllPhotos}>See All Photos</button>
                </div>
                <div
                  id="all-photo"
                  className="all-photo"
                >
                  <div className="row">
                    {renderPhotos("tour", true)}
                    {renderPhotos("christmas", true)}
                    {renderPhotos("diwali", true)}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-7">
              <div className="profile-tab">
                <div className="head-top">
                  <div className="profile-photo">
                    <img
                      src={profilePhoto}
                      alt="profile image"
                      className="img-fluid profile-pic"
                      height="60"
                      width="60"
                    />
                  </div>
                  {/* <input
                    type="text"
                    placeholder="What's on your mind, Stamens"
                  /> */}
                  <p className="profile-name">Events At Stamens</p>
                </div>

                <div className="photo-tab">
                  <a href="#diwali">Diwali Photo</a>
                  <a href="#christmas">Christmas Photo</a>
                  <a href="#tour">Tour Photo</a>
                </div>
              </div>

              <div className="posts">
                <div className="post">
                  <div className="head-top">
                    <div className="profile-photo">
                      <img
                        src={profilePhoto}
                        alt="profile image"
                        className="img-fluid profile-pic"
                        height="60"
                        width="60"
                      />
                    </div>
                    <p className="profile-name">Stamens Software</p>
                  </div>
                  <p className="discription" dangerouslySetInnerHTML={{ __html: tourDescription }} />
                  <div
                    id="tour"
                    className="post-photos"
                  >
                    {renderPhotos("tour")}
                    <div className="post-image-total">
                      <span>+ {photos.tour.length - 4}</span>
                    </div>
                  </div>
                </div>

                <div className="post">
                  <div className="head-top">
                    <div className="profile-photo">
                      <img
                        src={profilePhoto}
                        alt="profile image"
                        className="img-fluid profile-pic"
                        height="60"
                        width="60"
                      />
                    </div>
                    <p className="profile-name">Stamens Software</p>
                  </div>
                  <p className="discription" dangerouslySetInnerHTML={{ __html: christmasDescription }} />
                  <div
                    id="christmas"
                    className="post-photos"
                  >
                    {renderPhotos("christmas")}
                    <div className="post-image-total">
                      <span>+ {photos.christmas.length - 4}</span>
                    </div>
                  </div>
                </div>

                <div className="post">
                  <div className="head-top">
                    <div className="profile-photo">
                      <img
                        src={profilePhoto}
                        alt="profile image"
                        className="img-fluid profile-pic"
                        height="60"
                        width="60"
                      />
                    </div>
                    <p className="profile-name">Stamens Software</p>
                  </div>
                  <p className="discription" dangerouslySetInnerHTML={{ __html: diwaliDescription }} />
                  <div
                    id="diwali"
                    className="post-photos"
                  >
                    {renderPhotos("diwali")}
                    <div className="post-image-total">
                      <span>+ {photos.diwali.length - 4}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default GalleryNew;