import React from 'react';
import Footer from '../components/footer';
import Banner from '../components/banner';
import { Helmet } from 'react-helmet';
import './portfolio.css';


const portfolioItems = [
  {
    name: "66degrees",
    image: `/images/portfolio/66degrees.png`,
    link: "https://66degrees.com/"
  },
  {
    name: "cabi",
    image:`/images/portfolio/cabi.png`,
    link: "https://www.cabi.org/"
  },
  {
    name: "techolution",
    image: `/images/portfolio/techolution.png`,
    link: "https://www.techolution.com/"
  },
  {
    name: "scadea",
    image: `/images/portfolio/scadea.png`,
    link: "https://scadea.com/"
  },
  {
    name: "proit-canada",
    image: `/images/portfolio/proit-canada.png`,
    link: "https://proit-canada.com/"
  },
  {
    name: "nextlooptechnologies",
    image: `/images/portfolio/nextlooptechnologies.png`,
    link: "https://www.nextlooptechnologies.com/"
  }
];

const bannerDetails =
    {
        title: 'stamens software',
        subTitle: 'Our Work Portfolio'
    }

function Portfolio() {

  return (
    <>
     <Helmet>
    <title>Our Portfolio | Stamens Software - Experts in IT Solutions</title>
    <meta name="description" content="Meet the talented professionals behind Stamens Software. Our team of experienced developers, designers, and IT experts are committed to delivering cutting-edge technology solutions with excellence and innovation." />
    <meta name="keywords" content="Team, Stamens Software, IT Experts, Software Development, Web Designers, Technology Professionals, IT Solutions, Team of Professionals, Meet the Team" />
</Helmet>

    <Banner details={bannerDetails} />

    <section className="portfolio">
      <div className="container">
        <div className="row gy-4 justify-content-center">
          {portfolioItems.map((item, index) => (
            <div key={index} className="col-md-4">
              <div className="part">
                <img src={item.image} alt={`${item.name} webpage`} className="img-fluid" />
                <div className="details">
                  <p className="name">{item.name}</p>
                  <a href={item.link} target="_blank" rel="nofollow">Visit</a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>

    <section>
            <div className="container">
                <div className="col-lg-9 mx-auto">
                    <div className="connect-with-us">
                        <div className="content">
                            <h2 className="head">Let’s Connect With Us</h2>
                            <p>Get in touch with our skilled developers to begin receiving the best design and development services for your ultimate website</p>
                        </div>
                        <div className="btn-group">
                            <a href="https://www.stamenssoftware.com/contact"><i className="fa fa-clipboard-question"></i> Ask a Query</a>
                            <a href="tel:+911203500848"><i className="fa fa-phone" aria-hidden="true"></i> Call Now</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    <Footer />
    </>
  )
}

export default Portfolio
