import React from "react";
import { Link } from "react-router-dom";
import './services.css';
import Footer from "../components/footer";
import { Helmet } from "react-helmet";
import Banner from "../components/banner";
import CardComponent from "../components/cardComponent";
import LetsCode from "../components/letscode";
const researchImage = `/images/icons/search-service.png`;
const designImage = `/images/icons/webdesign-service.png`;
const developmentImage = `/images/icons/develop-service.png`;
const testingImage = `/images/icons/testing-service.png`;
const deploymentImage = `/images/icons/deploy-service.png`;
const maintenanceImage = `/images/icons/maintanance-service.png`;

const improvementImage = `images/improvement.png`;

const aspImage = `/images/icons/asp.svg`;
const nodeImage = `/images/icons/node.svg`;
const angularImage = `/images/icons/angular.svg`;
const reactImage = `/images/icons/react.svg`;
const pythonImage = `/images/icons/python.svg`;
const javaImage = `/images/icons/java.svg`;
const phpImage = `/images/icons/php.svg`;
const htmlImage = `/images/icons/html.svg`;
const vueImage = `/images/icons/vue.svg`;
const manWithLaptop = `/images/man-with-laptop.png`;

const customSoftwareDevelopment = `/images/icons/custom-software-development.png`;
const implementationDeployment = `/images/icons/implementation-deployment.png`;
const apiIntegrationsImg = `/images/icons/api-integrations.png`;
const uiuxDesign = `/images/icons/uiux-design.png`;
const mvpDevelopment = `/images/icons/mvp-development.png`;
const webDevelopment = `/images/icons/web-development.png`;
const cloudServices = `/images/icons/cloud-services.png`;
const mobileDevelopment = `/images/icons/mobile-development.png`;
const hireDedicatedTeam = `/images/icons/hire-dedicated-team.png`;
const eCommerceDevelopment = `/images/icons/e-commerce-development.png`;
const dataStorageAndManagement = `/images/icons/data-storage-and-management.png`;
const technicalSupport = `/images/icons/technical-support.png`;

const experienceServices = [
    {
        image: customSoftwareDevelopment,
        title: 'Custom Software Development',
        description: 'Our custom web development solutions accelerate workflows, boost revenues, and optimize business operations from concept-to-code and development-to-deployment.',
        button : true,
        to : '/custom-software-development-company'
    },
    {
        image: implementationDeployment,
        title: 'Implementation & Deployment',
        description: 'We devise an in-depth, comprehensive development process including software implementation & deployment plan, assessing your needs to deliver an enhanced user experience for end-users.',
        button: true,
        to : '/implementation-and-deployment'
    },
    {
        image: apiIntegrationsImg,
        title: 'API Integrations',
        description: 'We build custom APIs for all breeds of applications, adding functionality to your software systems and facilitating communication between your apps & others.',
        button: true,
        to : '/api-integration'
    },
    {
      image: uiuxDesign,
      title: 'UX/UI Design',
      description: 'Leverage the latest UI/UX technologies, architectures, and trends to design responsive & scalable apps that transform customer experiences across multiple channels.',
      button: true,
      to : '/best-ui-ux-design-services'
  },
  {
    image: mvpDevelopment,
    title: 'MVP Development',
    description: 'Have a brilliant idea for an app or website but unsure where to start? We specialize in rapid prototyping and development of Minimum Viable Products (MVPs). This allows you to test your concept with real users quickly, validate your idea, and get to market faster.',
    button: true,
    to : '/mvp-development'
  },
  {
    image: webDevelopment,
    title: 'Web Development',
    description: 'We craft innovative web solutions that go beyond just a website. We design intuitive interfaces and seamless user experiences to not only enhance your online presence but also drive real business growth. Imagine a website that captivates visitors and compels them to take action!',
    button: true,
    to : '/web-development-services'
  },
  {
    image: cloudServices,
    title: 'Cloud Services (DevOps, AWS, GCP, Azure)',
    description: 'The cloud holds immense potential for your business. Our expert cloud architects can help you unlock that potential. We offer comprehensive cloud services including secure migration, cost-optimization strategies, and cutting-edge DevOps solutions to ensure maximum scalability, reliability, and agility for your cloud infrastructure.',
    button: true,
    to : '/cloud-services'
  },
  {
    image: mobileDevelopment,
    title: 'Mobile Development',
    description: "Everyone's glued to their phones these days. Reach your audience exactly where they are with our custom-built mobile applications. Whether you need a native Android or iOS app, or a cross-platform solution with Flutter, we have the expertise to create engaging mobile experiences that will keep your users coming back for more.",
    button: true,
    to : '/mobile-app-development-services'
  },
  {
    image: hireDedicatedTeam,
    title: 'Hire Dedicated Team',
    description: 'Need to bolster your development team with top-tier talent? Look no further! We can provide you with highly skilled and experienced developers dedicated to bringing your vision to life. Think of it as seamlessly extending your in-house team with the perfect expertise you need.',
    button: true,
    to : '/hire-dedicated-team'
  },
  {
    image: eCommerceDevelopment,
    title: 'E-commerce Development',
    description: "Turn your online store into a sales powerhouse! We offer end-to-end e-commerce development solutions, from building a secure and user-friendly platform to integrating robust payment gateways and implementing effective marketing strategies. We'll help you transform your online store into a thriving e-commerce empire.",
    button: true,
    to : '/e-commerce-development-services'
  },
  {
    image: technicalSupport,
    title: 'Technical Support',
    description: "Peace of mind is priceless. Our reliable technical support services ensure that your website or application runs smoothly, uninterrupted. We'll be there to promptly address any technical issues that may arise, keeping your online presence running optimally.",
    button: true,
    to : '/technical-it-support'
  },
  {
    image: dataStorageAndManagement,
    title: 'Digital Marketing',
    description: "Effective digital marketing drives growth and visibility. Our tailored strategies ensure your brand reaches the right audience at the right time. From SEO to social media management, we focus on delivering measurable results that elevate your online presence and boost engagement.",
    button: true,
    to: '/digital-marketing-services'
  },
  {
    image: customSoftwareDevelopment,
    title: 'Application Maintanance',
    description: 'Application maintenance refers to the process of keeping an application up-to-date, functional, and optimized throughout its lifecycle. This includes resolving bugs, updating software, ensuring security, and enhancing the performance and usability of the application',
    button: true,
    to: '/application-maintenance-services'
  },
  {
    image: customSoftwareDevelopment,
    title: 'Data Migration',
    description: 'Data migration is the process of transferring data from one system, storage, or format to another, ensuring consistency and accessibility. It involves planning, mapping, and validating the data to minimize disruption and ensure smooth integration.',
    button: true,
    to: '/data-migration-services'
  },
  {
    image: customSoftwareDevelopment,
    title: 'IT Infrastructure',
    description: "IT infrastructure refers to the essential physical and virtual resources that support the operations of an organization's IT environment. This includes hardware (servers, storage, networks), software (operating systems, applications), and services (cloud, cybersecurity, data centers) required to manage and deliver IT solutions.",
    button: true,
    to: '/it-infrastructure-services'
  },
  {
    image: customSoftwareDevelopment,
    title: 'Software Security',
    description: 'Software security refers to the practices, tools, and techniques used to protect software applications from vulnerabilities, attacks, and unauthorized access. It involves identifying and addressing security risks during the development process, implementing encryption, authentication, and access controls, and regularly testing for weaknesses.',
    button: true,
    to: '/software-security-services'
  },
  {
    image: customSoftwareDevelopment,
    title: 'Product Lifecycle Management',
    description: 'Product Lifecycle Management (PLM) is the process of managing the entire lifecycle of a product from its initial concept, design, and production to its eventual retirement or disposal. It involves coordinating and integrating people, processes, business systems, and information to streamline product development and ensure efficiency.',
    button: true,
    to: '/product-lifecycle-management-services'
  },
  {
    image: customSoftwareDevelopment,
    title: 'QA Testing',
    description: 'QA testing (Quality Assurance testing) is a process to ensure that software applications meet the required standards and function correctly before release. It involves identifying bugs, errors, and potential issues through various testing methods like functional, performance, security, and usability tests.',
    button: true,
    to: '/qa-testing-services'
  }
  ];

  const cpServices = [
    {
        image: aspImage,
        title: '.Net',
        description: '.NET allows you to create dynamic, data-driven web applications using languages like C# or VB.NET.',

    },
    {
        image: nodeImage,
        title: 'Node JS',
        description: 'Node.js is a versatile platform that can be used to provide a wide range of services, thanks to its event-driven, non-blocking I/O model.',

    },
    {
        image: angularImage,
        title: 'Angular JS',
        description: 'AngularJS is primarily used for developing single-page applications (SPAs) where most of the functionality is handled on the client side.',
 
    },
    {
      image: reactImage,
      title: 'React JS',
      description: 'React applications can range from simple single-page applications (SPAs) to complex, feature-rich applications.',

  },
  {
    image: pythonImage,
    title: 'Python',
    description: 'Python is widely used in machine learning and artificial intelligence applications.',

  },
  {
    image: javaImage,
    title: 'Java',
    description: 'Java is popular in the development of financial and trading systems due to its performance, security, and reliability.',

  },
  {
    image: phpImage,
    title: 'PHP',
    description: 'PHP used to develop e-commerce websites, shopping carts, and payment gateways. It’s widely adopted for building online stores.',

  },
  {
    image: htmlImage,
    title: 'HTML/CSS',
    description: "HTML and CSS are the foundational technologies for building web pages. Even they primarily focus on structuring and styling content.",

  },
  {
    image: vueImage,
    title: 'Vue JS',
    description: 'Vue.js is a versatile JavaScript framework that excels in building user interfaces for web applications.',

  }
  ];

const developmentProcess = [
    {
        image: researchImage,
        title: 'Research',
        description: 'Consultation and Requirements gathering.'
    },
    {
        image: designImage,
        title: 'Design',
        description: 'Product Architecture, Design, & Prototype.',
    },
    {
        image: developmentImage,
        title: 'Development',
        description: 'Software Coding & Optimization.',
    },
    {
        image: testingImage,
        title: 'Testing',
        description: 'Quality Assurance & Trouble-shooting.',
    },
    {
        image: deploymentImage,
        title: 'Deployment',
        description: 'Launch, Beta Live, & Live.',
    },
    {
        image: maintenanceImage,
        title: 'Maintenance',
        description: 'Monitoring, Analysis, & Complete Support.',
    }
]

const bannerDetails =
    {
        title: 'Services',
        subTitle: 'Stamens Services'
    }

function  Services() {

  return (
   
<>
<Helmet>
    <title>Our Services | Stamens Software - Leading IT Solutions Provider</title>
    <meta name="description" content="Explore the diverse range of services offered by Stamens Software. From custom software and web development to cloud solutions and technical support, we deliver scalable, secure, and high-performance digital solutions for your business needs." />
    <meta name="keywords" content="Software Development, Web Development, IT Services, Cloud Solutions, API Integrations, Mobile Development, QA Testing, Digital Marketing, E-commerce Development, Technical Support" />
</Helmet>

    <Banner details={bannerDetails} />

<section className="experience">
            <div className="container">
                <h2 className="head text-center">
                    <span>Our Experience</span>
                    Industry-Specific Experience
                </h2>
                <p className="text-center mb-4 col-md-9 mx-auto">We perform world-class custom software development services for startups, small-to-midsize (SMB), and enterprise-size businesses.</p>
                
                <div className="row justify-content-center pt-4 gy-4">
                {experienceServices.map((card, index) => (
                <CardComponent key={index} cardProps={card} parentChildClass={'part'} childClassA={'icon'} childClassB={'content'}/>
            ))}

                </div>
            </div>
        </section>

<section className="cp-service">
    <div className="container">
        <div className="text-center">
            <h2 className="head">
                <span>TECHNOLOGY</span>
                KEY SKILLS
            </h2>
        </div>
        <div className="row justify-content-center mt-5">
        
        {cpServices.map((card, index) => (
                <CardComponent key={index} cardProps={card} parentClass={'col-md-4'} parentChildClass={'new-service-item shadow-box key-skill'} childClassA={'service-circle'} childClassB={'horizontal-info'} paraClass={'service-circle-para'} buttonClass={'custom-btn'}/>
            ))}
        </div>

        {/* <div className="text-center mb-3 mt-3">
            <Link className="custom-btn" to="services.html">know More About Our Services</Link>
        </div> */}
    </div>
</section>

<LetsCode />

<section className="hire-developers">
    <div className="container">
        <h4 className="head text-center"><span>Hire</span> Developers With Programming Expertise</h4>
        <p className="text-center mx-auto col-md-9">Our highly experienced software developers have a deep understanding of how to leverage top programming languages, frameworks, and other software development tools to create the ideal solution for your digital transformation goals.</p>
        <div className="row justify-content-center pt-4 align-items-center">
            <div className="col-lg-6">
                <div className="hireDevelopersList">
                    <div className="hireDevelopersListLeft">
                        <h5>Programming Languages</h5>
                        <ul>
                            <li>JavaScript</li>
                            <li>Java</li>
                            <li>Python</li>
                            <li>C#</li>
                            <li>SQL</li>
                        </ul>
                    </div>
                    <div className="hireDevelopersListRight">
                        <h5>FrameWorks</h5>
                        <ul>
                            <li>React</li>
                            <li>Angular</li>
                            <li>.NET</li>
                            <li>NodeJS</li>
                            <li>Ruby on Rails</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="hireDevelopersRight">
                    <div className="frame-list">
                        <img src={aspImage} alt="asp" className="img-fluid"/>
                        <img src={nodeImage} alt="node" className="img-fluid"/>
                        <img src={angularImage} alt="angular" className="img-fluid"/>
                        <img src={reactImage} alt="react" className="img-fluid"/>
                        <img src={pythonImage} alt="python" className="img-fluid"/>
                        <img src={javaImage} alt="java" className="img-fluid"/>
                        <img src={phpImage} alt="php" className="img-fluid"/>
                        <img src={htmlImage} alt="html" className="img-fluid"/>
                        <img src={vueImage} alt="vue" className="img-fluid"/>
                    </div>
                    <img src={manWithLaptop} alt="hire image" className="img-fluid p-5" />
                </div>
            </div>
        </div>
    </div>
</section>

<section className="serviceContent">
    <div className="container">
      <h4 className="head text-center pb-4"><span>Continuous</span> Improvement Initiatives</h4>
      <div className="row justify-content-center border rounded">
        <div className="col-lg-8">
            <p className="p-4">Our Continuous Improvement Initiatives encompass a wide range of strategies and methodologies, including Lean Six Sigma, Kaizen, and Agile practices. These frameworks provide us with structured approaches to identify inefficiencies, streamline processes, and enhance productivity. By empowering our employees to actively participate in improvement projects, we tap into a wealth of collective knowledge and experience, fostering a sense of ownership and pride in our shared success. Furthermore, our commitment to Continuous Improvement extends beyond internal operations to encompass our products and services. We actively seek customer feedback, analyze market trends, and incorporate emerging technologies to ensure that we deliver solutions that not only meet but exceed expectations. Whether it's refining existing products, developing innovative features, or exploring new markets, we remain agile and adaptable in our pursuit of excellence.</p>
        </div>
        <div className="col-lg-4 pe-0">
            <img src={improvementImage} alt="improvement" className="img-fluid rounded" />
        </div>
      </div>
    </div>
</section>

<section className="development">
            <div className="container">
                <h4 className="head text-center">
                    <span>Our Software</span> Development Process
                </h4>
                <p className="text-center mx-auto col-md-9">
                    We follow a well-structured and defined process with the end goal of creating a product that meets your business objectives
                </p>
                <div className="development-process row justify-content-center mt-4">
                {developmentProcess.map((card, index) => (
                <CardComponent key={index} cardProps={card} parentClass={'col-lg-4 col-md-6'} parentChildClass={'process-service'} childClassA={'box-icon'} childClassB={'content'} paraClass={'service-circle-para'} buttonClass={'custom-btn'}/>
            ))}
                </div>
            </div>
        </section>

<section className="solutions" id="list-item-7" style={{minHeight: 'auto'}}>
    <div className="container">
        <h4 className="head text-center col-md-7 mx-auto text-center">
            Talk To Our Experts
        </h4>
        <p className="tagline col-md-9 mx-auto text-center fs-4 mb-3 d-block">Looking For The Best It Business Solutions</p>

        <Link to="/contact" className="custom-btn">Contact Us</Link>
    </div>
</section>

<Footer />
</>

  );
}

export default Services;