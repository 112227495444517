import React, { useEffect, useRef, useState } from 'react';
import './contact.css';
import Footer from '../components/footer';
import { Helmet } from 'react-helmet';
import Banner from '../components/banner';
import { apiRequest } from '../apiUtils';

const bannerDetails =
    {
        title: 'Contact Us',
        subTitle: 'Contact With Stamens Software'
    }

function Contact() {

    const [formData, setFormData] = useState({
        Name: '',
        Email: '',
        Phone: '',
        Message: ''
      });

      const [errors, setErrors] = useState({
        Name: '',
        Email: '',
        Phone: '',
        Message: '',
        captcha: ''
      });
      const [successMessage, setSuccessMessage] = useState('');
      const [successError, setSuccessError] = useState('');
      
     const URL = `https://devstamenssoftware-api-f5avbucde6cmajbj.southindia-01.azurewebsites.net/api/Email/SubmitEnquiry`

      const formRef = useRef();

      const [captcha, setCaptcha] = useState('');
      const [captchaInput, setCaptchaInput] = useState('');
      const [isLoading, setIsLoading] = useState(false); 
      const canvasRef = useRef(null);

      const generateCaptcha = () => {
        const characters = 'ABCDEFGHJKMNPQRSTUVWXYZabcdefghjkmnpqrstuvwxyz123456789';
        let result = '';
        for (let i = 0; i < 6; i++) {
          result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        setCaptcha(result);
      };

      const drawCaptcha = () => {
        const canvas = canvasRef.current;
        if (canvas) {
          const ctx = canvas.getContext('2d');
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.fillStyle = '#f0f0f0';
          ctx.fillRect(0, 0, canvas.width, canvas.height);
    
          ctx.font = '30px Arial';
          ctx.fillStyle = '#000000';

          for (let i = 0; i < captcha.length; i++) {
            ctx.save();
            ctx.translate(30 * i + 10, Math.random() * 10 + 20);
            ctx.rotate(Math.random() * 0.2 - 0.1); 
            ctx.fillText(captcha[i], 0, 0);
            ctx.restore();
          }
    
          for (let i = 0; i < 5; i++) {
            ctx.strokeStyle = '#cccccc';
            ctx.beginPath();
            ctx.moveTo(Math.random() * canvas.width, Math.random() * canvas.height);
            ctx.lineTo(Math.random() * canvas.width, Math.random() * canvas.height);
            ctx.stroke();
          }
        }
      };
    
      useEffect(() => {
        generateCaptcha();
      }, []);

      useEffect(() => {
        drawCaptcha();
      }, [captcha]);

      const validateName = (name) => {
        const nameRegex = /^[A-Za-z\s'-]+$/;
        return nameRegex.test(name);
      };

      const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };
    
      const validatePhone = (phone) => {
        const phoneRegex = /^\+?[\d\s\-()]{7,15}$/;
        return phoneRegex.test(phone);
      };


      const handleChange =(e)=>{
        const { name, value } = e.target;
        if(name === 'captcha'){
          setCaptchaInput(value);
        }else {
        setFormData(prevState =>({
            ...prevState,
            [name] : value
        }));
      }
        
      }

      const handleSubmit = async (e) => {
        e.preventDefault();
        let valid = true;
        let newErrors = {}; 
    
        if (!formData.Name) {
            valid = false;
            newErrors.Name = 'Name is required';
        } else if (!validateName(formData.Name)) {
            valid = false;
            newErrors.Name = 'Only characters are allowed in the name!';
        }
    
        if (!formData.Email) {
            valid = false;
            newErrors.Email = 'Email is required';
        } else if (!validateEmail(formData.Email)) {
            valid = false;
            newErrors.Email = 'Not a valid mail';
        }
    
        if (!formData.Phone) {
            valid = false;
            newErrors.Phone = 'Phone number is required';
        } else if (!validatePhone(formData.Phone)) {
            valid = false;
            newErrors.Phone = 'Not a valid phone number';
        }
    
        if (!captchaInput) {
            valid = false;
            newErrors.captcha = 'Enter Captcha';
        } else if (captcha !== captchaInput) {
            valid = false;
            newErrors.captcha = 'CAPTCHA does not match';
            generateCaptcha();
        }
    
        if (valid) {
           
            try {
              setIsLoading(true);
                const response = await apiRequest({ url: URL, data: formData, method: 'POST' });
                setIsLoading(false);
                // alert('Mail sent successfully');
                  setSuccessMessage('Mail sent successfully');
                  setTimeout(()=>{
                    setSuccessMessage('');
                  }, 5000);
                
                setFormData({
                    Name: '',
                    Email: '',
                    Phone: '',
                    Message: '',
                });
                setCaptchaInput('');
                generateCaptcha();
            } catch (error) {
                  setSuccessError('There was an error sending the mail.');
                  setTimeout(()=>{
                    setSuccessError('');
                  }, 5000);
            } finally {
                setIsLoading(false);
                setErrors('');
            }
        } else {
            setErrors(newErrors);
            generateCaptcha();
        }
    };
    

  return (
    
<>
<Helmet>
    <title>Contact Us | Stamens Software - Get in Touch with Our IT Experts</title>
    <meta name="description" content="Have a question or need support? Contact Stamens Software today. Our team of IT experts is ready to assist you with software, web development, and other IT solutions. Reach out now!" />
    <meta name="keywords" content="Contact, Contact Stamens Software, IT Solutions, Software Development, Web Development, IT Support, Get in Touch, Reach Out, Noida IT Company" />
</Helmet>

    <Banner details={bannerDetails} />

<section className="py-4">
    <div className="container">
        <div className='form-possition-contactpage'>
        <div className="details">
                        <h2 className="head mb-4">
                            <span>Let's Talk</span>
                            Speak With Our Experts.
                        </h2>
                        <div className="part">
                            <div className="icon">
                                <i className="fa fa-phone"></i>
                            </div>
                            <div>
                                <p><a href="tel:+911203500847">+91-120 3500 847</a></p>
                                <p><a href="tel:+911203500848">+91-120 3500 848</a></p>
                                <p>MON - FRI 10AM - 7PM</p>
                            </div>
                        </div>
                        <div className="part">
                            <div className="icon">
                                <i className="fa fa-location-dot"></i>
                            </div>
                            <div>
                                <p>B-33, 2nd Floor, Sector 6, Noida, UP, India - 201301</p>
                            </div>
                        </div>
                        
                        <div className="part">
                            <div className="icon">
                                <i className="fa fa-envelope"></i>
                            </div>
                            <div>
                                <a href="mailto:contact@stamenssoftware.com">contact@stamenssoftware.com</a>
                                <p>24 X 7 online support</p>
                            </div>
                        </div>
                        <div className="part">
                            <ul className="social-icons p-0">
                                <li><a href="https://www.facebook.com/stamenssoftware/" aria-label="Social Media"><i className="fa-brands fa-facebook-f"></i></a></li>
                                <li><a href="https://www.instagram.com/stamens.software/" aria-label="Social Media"><i className="fa-brands fa-instagram"></i></a></li>
                                <li><a href="https://twitter.com/StamensSoftware" aria-label="Social Media"><i className="fa-brands fa-x-twitter"></i></a></li>
                                <li><a href="https://www.linkedin.com/company/stamenssoftware/mycompany/" aria-label="Social Media"><i className="fa-brands fa-linkedin-in"></i></a></li>
                                <li><a href="https://www.youtube.com/" aria-label="Social Media"><i className="fa-brands fa-youtube"></i></a></li>
                            </ul>
                        </div>
                    </div>

            <div className="form-side">
              <h3 className="head">Get In Touch</h3>
              {isLoading ? (
                    <div className="loading-overlay">
                      <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                      <p style={{color: '#000', fontWeight: 600, fontSize: '20px'}}>Submitting...</p>
                    </div>
                    ) : null}
              <form id="careerform" onSubmit={handleSubmit} ref={formRef} noValidate className={isLoading ? "blurred-form" : ""}>
                <div className="mb-2"><label htmlFor="name" className="form-label d-none">Name</label><input type="text" className="form-control" placeholder="Name" id="name" name="Name" value={formData.Name} onChange={handleChange} />{errors.Name && <small id="nameHelp" className="form-text text-danger">{errors.Name}</small>}
                </div>
                <div className="mb-2"><label htmlFor="email" className="form-label d-none">Email</label><input type="text" className="form-control" id="email" name="Email" value={formData.Email} placeholder="Email" onChange={handleChange} />{errors.Email && <small id="emailHelp" className="form-text text-danger">{errors.Email}</small>}
                </div>
                <div className="mb-2"><label htmlFor="phone" className="form-label d-none">Phone</label><input type="tel" className="form-control" id="phone" name="Phone" value={formData.Phone} placeholder="Phone" onChange={handleChange} />{errors.Phone && <small id="phoneHelp" className="form-text text-danger">{errors.Phone}</small>}
                </div>
                <div className="mb-2"><label htmlFor="message" className="form-label d-none">Message</label><textarea name="Message" id="Message" cols="15" rows="3" className="form-control" placeholder="Message" value={formData.Message} onChange={handleChange}></textarea></div>
                <div className="col-md-12 my-3" id="captcha-container">
                  <div className="d-flex gap-2 align-items-center">
                    <label htmlFor="captcha" className="form-label mb-1">Enter Captcha:</label>
                    <canvas ref={canvasRef} width={200} height={40}></canvas>
                  </div>
                  <div className="d-flex gap-2 align-items-center mt-2"><input type="text" className="form-control w-50" id="captcha-input" name="captcha" value={captchaInput} required onChange={handleChange} /><button type="button" onClick={generateCaptcha} id="refreshCaptcha" className="btn btn-secondary">Refresh CAPTCHA</button></div>
                  {errors.captcha && <small id="captchaHelp" className="form-text text-danger py-2">{errors.captcha}</small>}
                </div>
                <div className="mt-2 text-center"><button type="submit" className="custom-btn" name="Submit" value="submit">Submit</button></div>
                {successMessage && (
                  <small className="form-text text-success py-2">
                    {successMessage}
                  </small>
                )}
                {successError && (
                  <small id="phoneHelp" className="form-text text-danger">
                    {successError}
                    </small>
                )}
             </form>
            </div>
            
        </div>
    </div>
</section>

<section className="p-0 m-0">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3503.2552152183057!2d77.3158586!3d28.5921195!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce5ddf66d9991%3A0x8bd9694e9ba7ead8!2sStamens%20Software%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1724665365280!5m2!1sen!2sin" width="100%" height="500" style={{border: 0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </section>

<Footer />

</>

  )
}

export default Contact;