import React from 'react';
import './serviceCategory.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import Footer from '../components/footer';
import { Helmet } from 'react-helmet';
import Banner from '../components/banner';
import IconCard from '../components/iconCard';
import LetsCode from '../components/letscode';
import Serviceform from '../components/serviceform';
const LogoIconImage = `/images/logo-icon-image.png`;
const Customsoftwaredevelopment = `/images/icons/custom-software-development.png`;
const AppMobile = `/images/app-mobile.png`;
const aspImage = `/images/icons/asp.svg`;
const nodeImage = `/images/icons/node.svg`;
const angularImage = `/images/icons/angular.svg`;
const reactImage = `/images/icons/react.svg`;
const pythonImage = `/images/icons/python.svg`;
const javaImage = `/images/icons/java.svg`;
const phpImage = `/images/icons/php.svg`;
const vueImage = `/images/icons/vue.svg`;
const clients = [
    `/images/clients/66deg-client.png`,
    `/images/clients/cabi.png`,
    `/images/clients/pro-it.png`,
    `/images/clients/scadea-client.png`,
    `/images/clients/techolution-client.png`,
    `/images/clients/next-loop.png`
  ];


  const Features = [
    {
        title: 'User-Centric Design',
        description: 'Our process enhances user experience, ensuring intuitive navigation and appealing design for all users.'
      },
      {
        title: 'Cross-Platform Compatibility',
        description: "This flexibility maximizes your app's reach and guarantees a seamless, consistent experience for all users."
      },
      {
        title: 'Agile Development',
        description: 'This approach enables us to incorporate feedback quickly and deliver a final product that meets your vision.'
      },
  ]

  const bannerDetails =
  {
      title: 'Our Services',
      subTitle: 'Mobile App Development',
      description: 'Delivering customized mobile applications that combine cutting-edge technology with intuitive design, ensuring optimal performance and user satisfaction.'
  }

function MobileAppDevelopmentService() {

  return (

    <>
   <Helmet>
   <meta name="description" content="Stamens Software, a top IT company in Noida, offers mobile app development services, delivering innovative and user-friendly apps for iOS and Android platforms."/>
   <meta name="keywords" content="Custom Mobile App Development Services in Noida, Mobile App Development Services in Noida, iOS App Development Services in Noida"/>
   <title>Custom Mobile App Development Services | Stamens Software</title>
</Helmet>
    
    <Banner details={bannerDetails} />

        <section className="our-fetaures">
            <div className="container">
                <h3 className="head"><span>Features</span>
                    Our new features we build great
                </h3>
                <div className="row justify-content-center gy-4">
                {Features.map((card, index) => (
                <IconCard key={index} cardProps={card} paraClass={'small-head'} childClassB={'content'}/>
            ))}
                </div>
            </div>
        </section>

        <section className="work-process">
            <div className="container">
                <h3 className="head"><span>Software</span>
                    Easy Software Points
                </h3>
                <div className="row justify-content-center gy-4">
                    <div className="col-lg-3 col-md-6">
                        <div className="part">
                            <p className="small-head">API management</p>
                            <ul>
                                <li><i className="bi bi-check-circle-fill"></i> Secure Access</li>
                                <li><i className="bi bi-check-circle-fill"></i> Connectivity</li>
                                <li><i className="bi bi-check-circle-fill"></i> Engagement</li>
                                <li><i className="bi bi-check-circle-fill"></i> Secure Access</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="part">
                            <p className="small-head">Scheduled Reports</p>
                            <ul>
                                <li><i className="bi bi-check-circle-fill"></i> Publish anywhere</li>
                                <li><i className="bi bi-check-circle-fill"></i> Influencer</li>
                                <li><i className="bi bi-check-circle-fill"></i> Content Creation</li>
                                <li><i className="bi bi-check-circle-fill"></i> Prepare your brand</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="part">
                            <p className="small-head">API management</p>
                            <ul>
                                <li><i className="bi bi-check-circle-fill"></i> Secure Access</li>
                                <li><i className="bi bi-check-circle-fill"></i> Connectivity</li>
                                <li><i className="bi bi-check-circle-fill"></i> Engagement</li>
                                <li><i className="bi bi-check-circle-fill"></i> Secure Access</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="part">
                            <p className="small-head">Scheduled Reports</p>
                            <ul>
                                <li><i className="bi bi-check-circle-fill"></i> Publish anywhere</li>
                                <li><i className="bi bi-check-circle-fill"></i> Influencer</li>
                                <li><i className="bi bi-check-circle-fill"></i> Content Creation</li>
                                <li><i className="bi bi-check-circle-fill"></i> Prepare your brand</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <LetsCode />

        <section className="tracking">
            <div className="container">
                <div className="row d-flex align-items-center">
                    <div className="col-lg-6">
                        <h3 className="head"><span>We provide</span> Tracking platform for App Development</h3>
                        <p className="tagline">In today's fast-paced digital landscape, effective tracking is essential for successful app development. Our cutting-edge tracking platform provides developers with the insights they need to optimize performance, enhance user experience, and ensure timely project delivery.</p>
                        <ul>
                            <li><i className="bi bi-check-circle-fill"></i> Real-Time Analytics</li>
                            <li><i className="bi bi-check-circle-fill"></i> User Engagement Tracking</li>
                            <li><i className="bi bi-check-circle-fill"></i> Error Monitoring</li>
                            <li><i className="bi bi-check-circle-fill"></i> Customizable Dashboards</li>
                            <li><i className="bi bi-check-circle-fill"></i> Integration Capabilities</li>
                        </ul>
                    </div>
                    <div className="col-lg-6 text-center">
                        <img src={LogoIconImage} alt="logo-icon image" className="img-fluid" />
                    </div>
                </div>
            </div>
        </section>

        <section className="our-company-sec-mobile">
            <div className="container">
                <h4 className="head"><span>Why Stamens Software</span>We are one of the best IT companies?</h4>
                <div className="row gy-4">
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={Customsoftwaredevelopment} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>15+ Years in Industry</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={Customsoftwaredevelopment} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Source Code Delivery to Clients</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={Customsoftwaredevelopment} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Microsoft 365, Azure</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={Customsoftwaredevelopment} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Less than 24 hrs Guaranteed Response</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={Customsoftwaredevelopment} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Certified Developers</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={Customsoftwaredevelopment} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Dedicated Project Manager</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={Customsoftwaredevelopment} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Clients: USA, UK, Australia, etc.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={Customsoftwaredevelopment} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Handle Tight Deadlines</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="app-mobile">
            <div className="container">
                <div className="row d-flex align-items-center">
                    <div className="col-md-4">
                        <img src={AppMobile} alt="app-mobile" className="img-fluid" />
                    </div>
                    <div className="col-md-8">
                        <h3 className="head"> Partner with our client-centered team for top-quality software solutions </h3>
                        <p>Our team of skilled developers have years of experience in developing mobile apps for a range of platforms, including iOS, Android, and Windows. We use the latest tools and technologies to ensure that our apps are optimized for performance, security, and usability.</p>
                        <p>We create seamless and intuitive iOS applications that deliver top-notch user experiences for iPhones and iPads. Our Android apps are designed to perform exceptionally across a wide range of devices with an optimized user experience.</p>
                        <p>Using frameworks like Flutter and React Native, we build apps that work efficiently across both iOS and Android platforms. Our design team creates visually stunning, user-centered interfaces that engage users and enhance app usability.</p>            
                    </div>
                </div>
            </div>
        </section>
        
        <section className="technologie">
            <div className="container">
                <h3 className="head">
                    <span>Our Technologies</span>
                    All Technologies Software in stamens
                </h3>
                <div className="row gy-4">
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={aspImage} alt="asp" className="img-fluid" />
                            <h4>.Net</h4>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={nodeImage} alt="node" className="img-fluid" />
                            <h4>Node JS</h4>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={angularImage} alt="angular" className="img-fluid" />
                            <h4>Angular JS</h4>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={reactImage} alt="react" className="img-fluid" />
                            <h4>React JS</h4>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={pythonImage} alt="python" className="img-fluid" />
                            <h4>Python</h4>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={javaImage} alt="java" className="img-fluid" />
                            <h4>Java</h4>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={phpImage} alt="php" className="img-fluid" />
                            <h4>PHP</h4>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={vueImage} alt="vue" className="img-fluid" />
                            <h4>Vue JS</h4>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="trusted">
            <div className="container">
                <div className="text-center">
                    <h4 className="head"><span>Trusted Companies</span> Trusted by many companies</h4>
                    <p className="tagline col-md-9 mx-auto text-center fs-5 mb-5 d-block">Our commitment to quality and performance has earned us the trust of top brands, empowering them to deliver exceptional user experiences and achieve their business goals. Join a network of successful companies that leverage our technology to enhance their app development processes.</p>
                </div>
                <div className="swiper mySwiper3">
      <div className="swiper-wrapper">
        <Swiper
          modules={[Autoplay, Pagination]}
          spaceBetween={30}
          slidesPerView={5}
          autoplay={{
            delay: 1000,
            disableOnInteraction: true,
          }}
        >
          {clients.map((client, index) => (
            <SwiperSlide key={index} className="swiper-slide">
              <img src={client} alt={`Client ${index + 1}`} className="img-fluid" height="103" width="237" />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
            </div>
        </section>

        <Serviceform />

        <Footer />

</>
  )
}

export default MobileAppDevelopmentService;