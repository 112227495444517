import React from 'react'
import Footer from '../components/footer';
import { Helmet } from 'react-helmet';
import Banner from '../components/banner';
import ServiceComponent from '../components/servicecomponent';
import './qatesting.css';
import LetsCode from '../components/letscode';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import { Link } from 'react-router-dom';

const MobileTesting = `/images/qa-testing/mobile-testing.png`;
const WebTesting = `/images/qa-testing/web-testing.png`;
const EnterpriseTesting = `/images/qa-testing/enterprise-testing.png`;
const DatabaseTesting = `/images/qa-testing/database-testing.png`;
const SecurityTesting = `/images/qa-testing/security-testing.png`;
const AutomationTesting = `/images/qa-testing/test-automation.png`;
const SaasTesting = `/images/qa-testing/saas-testing.png`;
const APITesting = `/images/qa-testing/api-testing.png`;
const ManagedTesting = `/images/qa-testing/managed-testing.png`;
const QaStaffing  = `/images/qa-testing/qa-staffing.png`;
const QaAudit = `/images/qa-testing/qa-audit.png`;
const PerformanceTesting = `/images/qa-testing/performance-testing.png`;

const industry15 = `/images/icons/15-years-in-industry.png`;
const sourceCode = `/images/icons/source-code-delivery-to-clients.png`;
const microsoftAzure = `/images/icons/microsoft-365-azure.png`;
const response24 = `/images/icons/less-than-24-hrs-guaranteed-response.png`;
const certifiedDevelopers = `/images/icons/certified-developers.png`;
const projectManager = `/images/icons/dedicated-project-manager.png`;
const clients = `/images/icons/clients-usa-uk-australia.png`;
const tightDeadlines = `/images/icons/handle-tight-deadlines.png`;

const appMobile = `/images/app-mobile.png`;

const client = [
    `/images/clients/66deg-client.png`,
    `/images/clients/cabi.png`,
    `/images/clients/pro-it.png`,
    `/images/clients/scadea-client.png`,
    `/images/clients/techolution-client.png`,
    `/images/clients/next-loop.png`
  ];

const bannerDetails =
{
    title: 'Our Services',
    subTitle: 'QA TESTING SERVICES'
}

const UnlockExcellence =[
    {
        image: MobileTesting,
        heading: 'Mobile App Testing',
        para: 'Mobile phones are no longer just for calls and messages. With the rise of smartphones, we ensure your mobile applications deliver flawless performance and an exceptional user experience.',
      },
      {
        image: WebTesting,
        heading: 'Web App Testing',
        para: "Our skilled team thoroughly evaluates your web application's functionality, security, and usability, ensuring an impeccable user experience every time.",
      },
      {
        image: EnterpriseTesting,
        heading: 'Enterprise Testing',
        para: "From analyzing scalability to optimizing performance, we strengthen your digital ecosystem with our comprehensive enterprise testing services.",
      },
      {
        image: DatabaseTesting,
        heading: 'Database Testing',
        para: 'Enhance data integrity and system performance with our meticulous Database Testing services. We validate and ensure your database operates seamlessly.',
      },
      {
        image: SecurityTesting,
        heading: 'Security Testing',
        para: 'Protect your applications with our robust Security Testing. We identify vulnerabilities and fortify your systems against potential threats.',
      },
      {
        image: AutomationTesting,
        heading: 'Test Automation',
        para: "Streamline testing processes, improve accuracy, and accelerate product releases with our efficient Test Automation solutions.",
      },
      {
        image: SaasTesting,
        heading: 'SaaS App Testing',
        para: 'Ensure smooth functionality, security, and user experience with our SaaS App Testing services, enabling you to launch and maintain resilient applications.',
      },
      {
        image: APITesting,
        heading: 'API Testing',
        para: "Boost your software's reliability with our API Testing services, ensuring seamless integration, optimal functionality, and exceptional performance.",
      },
      {
        image: ManagedTesting,
        heading: 'Managed Testing',
        para: "Experience tailored testing solutions with our Managed Testing services. From planning to execution, we ensure a seamless and efficient process.",
      },
      {
        image: QaStaffing,
        heading: 'QA Staffing',
        para: 'Enhance your team with our QA Staffing solutions. We provide experienced QA professionals customized to suit your project requirements.',
      },
      {
        image: QaAudit,
        heading: 'QA Audit & Consulting',
        para: 'Our QA Audit & Consulting services provide in-depth assessments, pinpoint areas of improvement, and offer strategic recommendations for success.',
      },
      {
        image: PerformanceTesting,
        heading: 'Performance Testing',
        para: "Maximize your application’s efficiency with our Performance Testing services. We thoroughly analyze speed, responsiveness, and stability to ensure optimal performance.",
      }
]

const KeyServices =[
    {
        image: MobileTesting,
        heading: 'Test Automation',
        para: 'Accelerate the testing process with test automation, boosting efficiency and accuracy in software quality assurance.',
      },
      {
        image: WebTesting,
        heading: 'Application Penetration Testing',
        para: "Protect your digital assets by uncovering vulnerabilities with our comprehensive penetration testing, ensuring robust defense against cyber threats.",
      },
      {
        image: EnterpriseTesting,
        heading: 'Performance Testing',
        para: "Assess your application's performance under various conditions to guarantee optimal stability, responsiveness, and user satisfaction.",
      },
      {
        image: DatabaseTesting,
        heading: 'Infrastructure Security Testing',
        para: 'Secure your digital infrastructure with thorough testing, safeguarding your data and ensuring uninterrupted operations.',
      },
      {
        image: SecurityTesting,
        heading: 'Managed QA & Testing Services',
        para: 'Experience end-to-end quality assurance with our Managed QA & Testing Services, encompassing everything from test planning to defect management.',
      },
      {
        image: AutomationTesting,
        heading: 'DevSecOps',
        para: "Integrate development, security, and operations seamlessly with DevSecOps, enabling faster, more secure, and efficient software delivery.",
      }
]

function QaTesting() {
  return (
    <>
     <Helmet>
   <meta name="description" content="Stamens Software, a leading IT company in Noida, offers comprehensive QA and testing services to ensure your software is bug-free, secure, and high-performing."/>
   <meta name="keywords" content="QA Testing Services in Noida, Software Testing Services in Noida"/>
   <title>Software Testing and Quality Assurance (QA) Services by Stamens Software</title>
</Helmet>

    <Banner details={bannerDetails} descriptionClass={'banner-head text-center'}/>

    <section className="our-fetaures-testing">
            <div className="container">
                <h2 className="head">Unlock excellence with our QA services!</h2>
                <p className="tagline">At Stamens, we are dedicated to enhancing the quality and performance of SaaS and mobile applications. Our specialized QA solutions are designed to address the specific needs of product-based businesses, delivering a seamless user experience and top-tier functionality.</p>
                <div className="row gy-4">
                {UnlockExcellence.map((card, index) => (
               <ServiceComponent  key={index} serviceprops={card} parentClass={'col-md-4'} childClassA={'small-head'} />
            ))}  
                    </div>
                </div>
        </section>

        <LetsCode />

        <section className="work-process-testing">
            <div className="container">
                <h3 className="head text-center">Our Key Service and Steps</h3>
                <p className="tagline text-center">Accelerate testing with precision for unmatched efficiency and Safeguarding digital assets with proactive vulnerability detection.</p>
                <div className="row gy-4">
                {KeyServices.map((card, index) => (
               <ServiceComponent  key={index} serviceprops={card} parentClass={'col-md-6'} childClassA={'small-head'} />
            ))}  
                </div>
            </div>
        </section>

        <section className="our-company-testing">
            <div className="container">
                <h4 className="head">
                    <span>Why Stamens Software</span>
                    We are one of the best IT companies?
                </h4>
                <div className="row gy-4">
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={industry15} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>15+ Years in Industry</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={sourceCode} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Source Code Delivery to Clients</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={microsoftAzure} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Microsoft 365, Azure</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={response24} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Less than 24 hrs Guaranteed Response</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={certifiedDevelopers} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Certified Developers</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={projectManager} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Dedicated Project Manager</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={clients} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Clients: USA, UK, Australia, etc.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={tightDeadlines} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Handle Tight Deadlines</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>   

        <section className="app-mobile-testing">
                    <div className="container">
                        <div className="row d-flex align-items-center">
                            <div className="col-md-4">
                                <img src={appMobile} alt="app-mobile" className="img-fluid" />
                            </div>
                            <div className="col-md-8">
                                <h3 className="head"> Partner with our client-centered team for top-quality software solutions </h3>
                                <p>Our team of skilled developers have years of experience in developing mobile apps for a range of platforms, including iOS, Android, and Windows. We use the latest tools and technologies to ensure that our apps are optimized for performance, security, and usability.</p>
                                <p>We create seamless and intuitive iOS applications that deliver top-notch user experiences for iPhones and iPads. Our Android apps are designed to perform exceptionally across a wide range of devices with an optimized user experience.</p>
                                <p>Using frameworks like Flutter and React Native, we build apps that work efficiently across both iOS and Android platforms. Our design team creates visually stunning, user-centered interfaces that engage users and enhance app usability.</p>  
                                <Link to="/contact" className="hire-btn">Hire Us Now</Link>          
                            </div>
                        </div>
                    </div>
                </section>

                <section className="trusted-testing">
            <div className="container">
                <div className="text-center">
                    <h4 className="head"><span>Trusted Companies</span> Trusted by many companies</h4>
                    <p className="tagline col-md-9 mx-auto text-center fs-5 mb-5 d-block">Our commitment to quality and performance has earned us the trust of top brands, empowering them to deliver exceptional user experiences and achieve their business goals. Join a network of successful companies that leverage our technology to enhance their app development processes.</p>
                </div>
                <Swiper
            modules={[Autoplay, Pagination]}
              className="mySwiper3"
              spaceBetween={30}
              slidesPerView={5}
              autoplay={{
                delay: 1000,
                disableOnInteraction: true,
              }}
            >
              {client.map((client, index) => (
                <SwiperSlide key={index} className='swiper-slide'>
                  <img src={client} alt={`Client ${index + 1}`} className="img-fluid" height="103" width="237" />
                </SwiperSlide>
              ))}
            </Swiper>
            </div>
        </section> 
     
     <Footer />
    </>
  )
}

export default QaTesting;
