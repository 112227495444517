import React from 'react';
import './apiIntegration.css';
import Footer from '../components/footer';
import { Helmet } from 'react-helmet';
import Banner from '../components/banner';
import LetsCode from '../components/letscode';
import CardComponent from '../components/cardComponent';
import Process from '../components/process.js';

const apiIntegrationsImg = `/images/icons/api-integrations.png`;
const customSoftwareDevelopment = `/images/icons/custom-software-development.png`;
const apiConcept = `/images/api-concept.png`;
const aspImage = `/images/icons/asp.svg`;
const nodeImage = `/images/icons/node.svg`;
const angularImage = `/images/icons/angular.svg`;
const reactImage = `/images/icons/react.svg`;
const pythonImage = `/images/icons/python.svg`;
const javaImage = `/images/icons/java.svg`;
const phpImage = `/images/icons/php.svg`;
const vueImage = `/images/icons/vue.svg`;

const industry15 = `/images/icons/15-years-in-industry.png`;
const sourceCode = `/images/icons/source-code-delivery-to-clients.png`;
const microsoftAzure = `/images/icons/microsoft-365-azure.png`;
const response24 = `/images/icons/less-than-24-hrs-guaranteed-response.png`;
const certifiedDevelopers = `/images/icons/certified-developers.png`;
const projectManager = `/images/icons/dedicated-project-manager.png`;
const client = `/images/icons/clients-usa-uk-australia.png`;
const tightDeadlines = `/images/icons/handle-tight-deadlines.png`;


const processCard = [
    {
        spanContent: 'Process',
        heading: 'Our API Development Process',
        subheading: ' we follow a structured approach to API development that ensures quality, efficiency, and alignment with your business goals. Here’s how our process works -',
        processItems: [
            {
                num: '01',
                image: apiIntegrationsImg,
                contentheading: 'Planning and Design',
                description : 'Based on the insights gathered, our team creates a comprehensive project plan.'
            },
            {
                num: '02',
                image: apiIntegrationsImg,
                contentheading: 'Development',
                description : 'With a solid plan in place, our developers start building the API.'
            },
            {
                num: '03',
                image: apiIntegrationsImg,
                contentheading: 'Develop',
                description : 'Identify the purpose, functionalities, and target users of the API.'
            },
            {
                num: '04',
                image: apiIntegrationsImg,
                contentheading: 'Testing',
                description : 'We conduct extensive testing, including unit tests, integration tests, and performance testing.'
            },
            {
                num: '05',
                image: apiIntegrationsImg,
                contentheading: 'Deployment',
                description : 'Once testing is complete, we proceed with deployment.'
            },
            {
                num: '06',
                image: apiIntegrationsImg,
                contentheading: 'Monitoring and Support',
                description : 'we offer ongoing monitoring and support to address any potential issues and ensure optimal performance.'
            }
        ]
    },
    {

    }
]

const Api = [
    {
        image: customSoftwareDevelopment,
        title: 'RESTful APIs',
        description: 'RESTful APIs are designed around REST (Representational State Transfer) principles, offering lightweight communication between client and server.'
      },
      {
        image: customSoftwareDevelopment,
        title: 'GraphQL APIs',
        description: 'GraphQL APIs allow clients to request specific data, reducing the amount of data transferred over the network.'
      },
      {
        image: customSoftwareDevelopment,
        title: 'Public APIs',
        description: 'Public APIs are designed for external developers and third-party applications, promoting collaboration and extending the functionality of your platform.'
      },
      {
        image: customSoftwareDevelopment,
        title: 'Private APIs',
        description: 'Private APIs are designed for internal use within your organization, allowing different systems and teams to communicate efficiently.'
      },

    ]

    const bannerDetails =
    {
        title: 'Our Services',
        subTitle: 'API Integrations',
        description: 'We build custom APIs for all breeds of applications, adding functionality to your software systems and facilitating communication between your apps & others.'
    }

function ApiIntegration() {
  return (
   <>


<Helmet>
<meta name="description" content=" Stamens Software, a leading IT company in Noida, specializes in API integration services, connecting applications seamlessly for optimized business workflows."/>
<meta name="keywords" content=" API Integration Services in Noida, Custom API Interface Integrations in Noida "/>
    <title>Custom API Development & API Integration Services - Stamens Software</title>
    </Helmet>

    <Banner details={bannerDetails} />

        <section className="dev-service bg-white">
            <div className="container">
                <h2 className="head text-center">
                    <span>Our End to End</span>
                    API Development Services
                </h2>
                <p className="text-center fs-5 pb-4"> we specialize in comprehensive API development services tailored to meet your unique business needs. Our team of experts is dedicated to creating seamless integrations that enhance the functionality of your software applications.</p>
                <div className="row justify-content-center gy-4">
                    <div className="col-md-4">
                        <div className="part">
                            <img src={apiIntegrationsImg} alt="api-integrations" className="img-fluid" />
                            <h3 className="name">Custom Solutions</h3>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="part">
                            <img src={apiIntegrationsImg} alt="api-integrations" className="img-fluid" />
                            <h3 className="name">Scalability</h3>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="part">
                            <img src={apiIntegrationsImg} alt="api-integrations" className="img-fluid" />
                            <h3 className="name">Security First</h3>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="part">
                            <img src={apiIntegrationsImg} alt="api-integrations" className="img-fluid" />
                            <h3 className="name">Comprehensive Integration</h3>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="part">
                            <img src={apiIntegrationsImg} alt="api-integrations" className="img-fluid" />
                            <h3 className="name">Cross-Platform Compatibility</h3>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="part">
                            <img src={apiIntegrationsImg} alt="api-integrations" className="img-fluid" />
                            <h3 className="name">Consultation</h3>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        {processCard.map((item, index) => (
        <Process 
          key={index}
          spanContent={item.spanContent}
          heading={item.heading}
          subheading={item.subheading}
          processItems={item.processItems}
          parentClass={"dev-process"}
        />
      ))}

  <LetsCode />

        <section className="why-us">
            <div className="container">
                <h3 className="head text-center">
                    <span>Types of </span>
                    API We Develop
                </h3>
                <p className="text-center fs-5 pb-4">we specialize in creating a variety of APIs to meet diverse business needs.</p>
                <div className="row gy-4">
                {Api.map((card, index) => (
                <CardComponent key={index} cardProps={card} parentClass={'col-lg-3 col-md-6'} parentChildClass={'part'} childClassA={'icon'} childClassB={'content'}/>
            ))}
                </div>
            </div>
        </section>

        <section className="technologie">
            <div className="container">
                <h3 className="head">
                    <span>Our Technologies</span>
                    All Technologies Software in stamens
                </h3>
                <div className="row gy-4">
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={aspImage} alt="asp" className="img-fluid" />
                            <h4>.Net</h4>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={nodeImage} alt="node" className="img-fluid" />
                            <h4>Node JS</h4>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={angularImage} alt="angular" className="img-fluid" />
                            <h4>Angular JS</h4>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={reactImage} alt="react" className="img-fluid" />
                            <h4>React JS</h4>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={pythonImage} alt="python" className="img-fluid" />
                            <h4>Python</h4>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={javaImage} alt="java" className="img-fluid" />
                            <h4>Java</h4>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={phpImage} alt="php" className="img-fluid" />
                            <h4>PHP</h4>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={vueImage} alt="vue" className="img-fluid" />
                            <h4>Vue JS</h4>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="partner bg-white">
            <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-5 pt-4">
                            <img src={apiConcept} alt="vision" className="img-fluid rounded" />
                        </div>
                        <div className="col-lg-7">
                            <h4 className="head">
                                Partner with our client-centered team for top-quality software solutions
                            </h4>
                            <p className="mt-n2 mb-25">Looking for top-notch software developers? Look no further! Our team of certified experts is dedicated to providing a client-centered approach and delivering the highest quality software using the software development life cycle (SDLC). Our team is comprised of experienced programmers who are known for their outstanding consulting services and for writing clean code.</p>
                            <p className="mt-n2 mb-25">Trust us, one of the most reliable software outsourcing companies, to make your mission-critical project a success by hiring one of our talented software developers.</p>
                            <a href="#" className="hire-btn">Hire Us Now</a>
                        </div>
                    </div>
                </div>
        </section>

        <section className="our-company-api-integration">
            <div className="container">
                <h4 className="head">
                    <span>Why Stamens Software</span>
                    We are one of the best IT companies?
                </h4>
                <div className="row gy-4">
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={industry15} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>15+ Years in Industry</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={sourceCode}  alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Source Code Delivery to Clients</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={microsoftAzure}  alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Microsoft 365, Azure</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={response24}  alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Less than 24 hrs Guaranteed Response</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={certifiedDevelopers}  alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Certified Developers</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={projectManager}  alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Dedicated Project Manager</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={client}  alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Clients: USA, UK, Australia, etc.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={tightDeadlines}  alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Handle Tight Deadlines</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

   <Footer />
   </>
  )
}

export default ApiIntegration;
