import React from "react";
import './serviceItem.css';
import Footer from "../components/footer";
import { Helmet } from "react-helmet";
import Banner from "../components/banner";
import IconCard from "../components/iconCard";
import LetsCode from "../components/letscode";
import Serviceform from "../components/serviceform";
const customSoftwareDevelopment = `/images/icons/custom-software-development.png`;
const eCommerceDevelopment = `/images/ecommerce-developemt.png`;
const appMobile = `/images/app-mobile.png`;
const aspImage = `/images/icons/asp.svg`;
const nodeImage = `/images/icons/node.svg`;
const angularImage = `/images/icons/angular.svg`;
const reactImage = `/images/icons/react.svg`;
const pythonImage = `/images/icons/python.svg`;
const javaImage = `/images/icons/java.svg`;
const phpImage = `/images/icons/php.svg`;
const vueImage = `/images/icons/vue.svg`;
const deg = `/images/clients/66deg-client.png`;
const cabi = `/images/clients/cabi.png`;
const scadea = `/images/clients/scadea-client.png`;
const techolution = `/images/clients/techolution-client.png`;
const proIt = `/images/clients/pro-it.png`;
const nextLoop = `/images/clients/next-loop.png`;

const industry15 = `/images/icons/15-years-in-industry.png`;
const sourceCode = `/images/icons/source-code-delivery-to-clients.png`;
const microsoftAzure = `/images/icons/microsoft-365-azure.png`;
const response24 = `/images/icons/less-than-24-hrs-guaranteed-response.png`;
const certifiedDevelopers = `/images/icons/certified-developers.png`;
const projectManager = `/images/icons/dedicated-project-manager.png`;
const client = `/images/icons/clients-usa-uk-australia.png`;
const tightDeadlines = `/images/icons/handle-tight-deadlines.png`;

const featuresEcommerce = [
    {
        title: 'Creation Of E-commerce Sites',
        description: 'we create customized e-commerce sites that are visually appealing and user-friendly, designed to convert visitors into loyal customers. Our team combines innovative design with powerful functionality to help your business thrive online.'
    },
    {
        title: 'Generate Online Sales',
        description: 'we focus on implementing targeted strategies that boost your online sales. From optimizing product listings to leveraging data-driven marketing techniques, we help you attract and retain customers, ensuring sustainable growth for your e-commerce business.'
    },
  ]

  const bannerDetails =
  {
      title: 'Our Services',
      subTitle: 'E Commerce Development',
      description: 'we specialize in crafting robust e-commerce solutions that enhance user engagement and drive sales.',
      buttonText : 'Work with Us',
      button: true
  }

function ECommerceDevelopment() {

    return(
      
<>
<Helmet>
   <meta name="description" content="We offer custom ecommerce website design and development services, and we create any universal and unique features needed for smooth customer journeys."/>
   <meta name="keywords" content="E-Commerce Development Services, E-Commerce Development Service in Noida, Online Store Development in Noida, E-Commerce Development Company"/>
   <title>E-Commerce Website Design & Development Services - Stamens Software</title>
</Helmet>

    <Banner details={bannerDetails} />

<section className="our-fetaures">
    <div className="container">
        <div className="row justify-content-center gy-4">
        {featuresEcommerce.map((card, index) => (
                <IconCard key={index} cardProps={card} parentChildClass={'part'} parentClass={'col-lg-6 col-md-6'} iconClass={'bi bi-database'} paraClass={'small-head'} childClassA={'icon'} childClassB={'content'} />
            ))}
        </div>
    </div>
</section>

<section className="welcome">
    <div className="container">
        <div className="row justify-content-center gy-4">
            <div className="col-lg-7 col-md-7">
                <div className="content">
                    <h2 className="head"><span>Welcome to Stamens</span>
                        Discover the power of E-commerce website
                    </h2>
                    <p>we specialize in creating dynamic and user-friendly e-commerce websites that drive sales and enhance customer experiences. Our expert team utilizes the latest technologies to build scalable solutions tailored to your business needs, ensuring a seamless online shopping journey for your customers.</p>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="part">
                                <div className="icon">
                                    <img src={customSoftwareDevelopment} alt="icon 1" className="img-fluid" />
                                </div>
                                <div className="part-content">
                                    <p className="small-head">Boost Your Online Sales</p>
                                    <p>Boost your revenue with a robust e-commerce platform designed to attract and retain customers. Our development process focuses on optimizing conversion rates and enhancing user engagement, helping you turn visitors into loyal buyers.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="part">
                                <div className="icon">
                                    <img src={customSoftwareDevelopment} alt="icon 2" className="img-fluid" />
                                </div>
                                <div className="part-content">
                                    <p className="small-head">Custom Solutions for Every Business</p>
                                    <p>No two businesses are the same, and neither should their e-commerce solutions be. We provide tailored development services that align with your unique business goals, ensuring a personalized approach that sets you apart in the competitive online marketplace.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-5 col-md-5">
                <div className="right-image h-100 d-flex align-items-center justify-content-center">
                    <img src={eCommerceDevelopment} alt="ecommerce" className="img-fluid" />
                </div>
            </div>
        </div>
    </div>
</section>

<section className="work-process">
    <div className="container">
        <h2 className="head"><span>Welcome to Stamens</span>
            How it is going?
        </h2>
        <div className="row justify-content-around gy-4">
            <div className="col-lg-4">
                <div className="part">
                    <p className="small-head">Discovery & Planning</p>
                    <p>We start by understanding your business needs and goals to create a tailored e-commerce strategy.</p>
                </div>
            </div>
            <div className="col-lg-4">
                <div className="part">
                    <p className="small-head">Design & Development</p>
                    <p>Our team crafts a user-friendly interface and robust backend systems to ensure a seamless shopping experience.</p>
                </div>
            </div>
            <div className="col-lg-4">
                <div className="part">
                    <p className="small-head">Integration</p>
                    <p>We integrate essential tools and platforms to streamline operations and enhance functionality.</p>
                </div>
            </div>
            <div className="col-lg-4">
                <div className="part">
                    <p className="small-head">Testing & Launch</p>
                    <p>Rigorous testing ensures everything works flawlessly before your online store goes live.</p>
                </div>
            </div>
            <div className="col-lg-4">
                <div className="part">
                    <p className="small-head">Ongoing Support</p>
                    <p>After launch, we provide continuous support and updates to help your business adapt and thrive.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<LetsCode />

<section className="technologie">
    <div className="container">
        <h3 className="head">
            <span>Our Technologies</span>
            All Technologies Software in stamens
        </h3>
        <div className="row gy-4">
            <div className="col-lg-3 col-md-4">
                <div className="icons">
                    <img src={aspImage} alt="asp" className="img-fluid" />
                    <h4>.Net</h4>
                </div>
            </div>
            <div className="col-lg-3 col-md-4">
                <div className="icons">
                    <img src={nodeImage} alt="node" className="img-fluid" />
                    <h4>Node JS</h4>
                </div>
            </div>
            <div className="col-lg-3 col-md-4">
                <div className="icons">
                    <img src={angularImage} alt="angular" className="img-fluid" />
                    <h4>Angular JS</h4>
                </div>
            </div>
            <div className="col-lg-3 col-md-4">
                <div className="icons">
                    <img src={reactImage} alt="react" className="img-fluid" />
                    <h4>React JS</h4>
                </div>
            </div>
            <div className="col-lg-3 col-md-4">
                <div className="icons">
                    <img src={pythonImage} alt="python" className="img-fluid" />
                    <h4>Python</h4>
                </div>
            </div>
            <div className="col-lg-3 col-md-4">
                <div className="icons">
                    <img src={javaImage} alt="java" className="img-fluid" />
                    <h4>Java</h4>
                </div>
            </div>
            <div className="col-lg-3 col-md-4">
                <div className="icons">
                    <img src={phpImage} alt="php" className="img-fluid" />
                    <h4>PHP</h4>
                </div>
            </div>
            <div className="col-lg-3 col-md-4">
                <div className="icons">
                    <img src={vueImage} alt="vue" className="img-fluid" />
                    <h4>Vue JS</h4>
                </div>
            </div>
        </div>
    </div>
</section>

<section className="app-mobile">
    <div className="container">
        <div className="row d-flex align-items-center">
            <div className="col-md-4">
                <img src={appMobile} alt="app-mobile" className="img-fluid" />
            </div>
            <div className="col-md-8">
                <h3 className="head"> Partner with our client-centered team for top-quality software solutions </h3>
                <p>Our team of skilled developers have years of experience in developing mobile apps for a range of platforms, including iOS, Android, and Windows. We use the latest tools and technologies to ensure that our apps are optimized for performance, security, and usability.</p>
                <p>We create seamless and intuitive iOS applications that deliver top-notch user experiences for iPhones and iPads. Our Android apps are designed to perform exceptionally across a wide range of devices with an optimized user experience.</p>
                <p>Using frameworks like Flutter and React Native, we build apps that work efficiently across both iOS and Android platforms. Our design team creates visually stunning, user-centered interfaces that engage users and enhance app usability.</p>            
            </div>
        </div>
    </div>
</section>

<section className="our-company-sec-mobile">
    <div className="container">
        <h4 className="head"><span>Why Stamens Software</span>We are one of the best IT companies?</h4>
        <div className="row gy-4">
            <div className="col-lg-3 col-md-4">
                <div className="icons">
                    <div className="icon"><img src={industry15} alt="Custom Software Development" className="img-fluid" /></div>
                    <p>15+ Years in Industry</p>
                </div>
            </div>
            <div className="col-lg-3 col-md-4">
                <div className="icons">
                    <div className="icon"><img src={sourceCode} alt="Custom Software Development" className="img-fluid" /></div>
                    <p>Source Code Delivery to Clients</p>
                </div>
            </div>
            <div className="col-lg-3 col-md-4">
                <div className="icons">
                    <div className="icon"><img src={microsoftAzure} alt="Custom Software Development" className="img-fluid" /></div>
                    <p>Microsoft 365, Azure</p>
                </div>
            </div>
            <div className="col-lg-3 col-md-4">
                <div className="icons">
                    <div className="icon"><img src={response24} alt="Custom Software Development" className="img-fluid" /></div>
                    <p>Less than 24 hrs Guaranteed Response</p>
                </div>
            </div>
            <div className="col-lg-3 col-md-4">
                <div className="icons">
                    <div className="icon"><img src={certifiedDevelopers} alt="Custom Software Development" className="img-fluid" /></div>
                    <p>Certified Developers</p>
                </div>
            </div>
            <div className="col-lg-3 col-md-4">
                <div className="icons">
                    <div className="icon"><img src={projectManager} alt="Custom Software Development" className="img-fluid" /></div>
                    <p>Dedicated Project Manager</p>
                </div>
            </div>
            <div className="col-lg-3 col-md-4">
                <div className="icons">
                    <div className="icon"><img src={client} alt="Custom Software Development" className="img-fluid" /></div>
                    <p>Clients: USA, UK, Australia, etc.</p>
                </div>
            </div>
            <div className="col-lg-3 col-md-4">
                <div className="icons">
                    <div className="icon"><img src={tightDeadlines} alt="Custom Software Development" className="img-fluid" /></div>
                    <p>Handle Tight Deadlines</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section className="trusted">
    <div className="container">
        <div className="text-center">
            <h4 className="head"><span>Trusted Companies</span> Trusted by many companies</h4>
            <p className="tagline col-md-9 mx-auto text-center fs-5 mb-5 d-block">Our commitment to quality and performance has earned us the trust of top brands, empowering them to deliver exceptional user experiences and achieve their business goals. Join a network of successful companies that leverage our technology to enhance their app development processes.</p>
        </div>
        <div className="images">
            <img src={deg} alt="66deg" className="img-fluid" height="103" width="237" />
            <img src={cabi} alt="cabi" className="img-fluid" height="103" width="237" />
            <img src={proIt} alt="Pro IT" className="img-fluid" height="103" width="237" />
            <img src={scadea} alt="scadea" className="img-fluid" height="103" width="237" />
            <img src={techolution} alt="techolution" className="img-fluid" height="103" width="237" />
            <img src={nextLoop} alt="next-loop" className="img-fluid" height="103" width="237" />
        </div>
    </div>
</section>  
<Serviceform />
<Footer />
</>

    )

}

export default ECommerceDevelopment;